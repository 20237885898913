import React from "react";
import CustomLink from "../../customLink/CustomLink";

import { StyledInfoMenuItem } from "./styled-dropdown-nav";

const NavInfoMenuItem = ({ to, rel, icon, linkTitle, target, className }) => {
  return (
    <StyledInfoMenuItem className={`info-menu-item ${className}`}>
      <CustomLink to={to} rel={rel} target={target} className="info-menu-link" activeClassName="info-menu-link_current">
        { icon && <div className="info-menu-link__icon">
          <span className={`icon-${icon}`} />
        </div>}
        <div className="info-menu-link__title">
          {linkTitle}
        </div>
      </CustomLink>
    </StyledInfoMenuItem>
  );
};

export default NavInfoMenuItem;