import React from "react";
import RegistrationContainer from "../../components/registration/registration.component";

const RegistrationPage = () => {

  return (
    <RegistrationContainer />
  );
};

export default React.memo(RegistrationPage);
