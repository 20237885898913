import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Telegram } from '../../assets/images/icons/telegram.svg';

const FooterComponentContact = () => {
  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <div className="links-wrapper__item">
      <div className="links-wrapper__item__title">
        {ready && t("linkContactTitle")}
      </div>
      <div className="links-wrapper__item__contact">
        <ul className="links-wrapper__item__list">
          <li>
            <span className="icon-contact-email"></span>
            E-mail:
            <a className="email" href="mailto:info@coin24.io">
              info@coin24.io
            </a>
          </li>
          <li>
            <span className="icon-technical-support"></span>&nbsp;{ready && t("linkContactTechSupp")}
            <p className="working-hours">08:00-24:00 (UTC+2)</p>
          </li>
        </ul>
        <div className="about__our-social">
          <a
            href="tg://resolve?domain=coin24io"
            target="_blank"
            rel="noreferrer"
            aria-label="telegram"
          >
            <Telegram />
          </a>
        </div>
      </div>
    </div>
)
}

export default FooterComponentContact