import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import merchantIcons from "../../assets/images/international-payment-systems.svg";

import { StyledMerchantsWrapper } from "./styled-footer";

const FooterMerchants = ({ className }) => {

  return (
    <StyledMerchantsWrapper className={className}>
      <LazyLoadImage
        src={merchantIcons}
        alt="Visa, Mastercard"
        width={203}
        height={25}
      />
    </StyledMerchantsWrapper>
  );
};

export default FooterMerchants;