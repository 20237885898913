import React from 'react';
import { StyledLoadingSidebar, StyledShowNavMenu, StyledShowSidebar } from '../styled-sidebar';
import Spinner from '../../../spinner/spinner.component';

const SidebarButtonSkeleton = ({text, type}) => {
  return (
    <StyledLoadingSidebar type={type} data-testid="profile-preloader">
      <StyledShowNavMenu
        //onClick={showDrawer}
      >
        <Spinner color="#fff" type="moonLoader" size="20px"/>
      </StyledShowNavMenu>
    </StyledLoadingSidebar>
  );
};

export default SidebarButtonSkeleton;