const defaultLanguage = "ru";

const supportedLanguages = {
  "ru": {
    "url": "",
    "routePrefix": "ru",
    "regex": "\\/$|\\b",
    "description": "Русский"
  },
  "ua": {
    "url": "/ua",
    "routePrefix": "ua",
    "regex": "\\/ua\\b",
    "description": "Українська"
  },
  "en": {
    "url": "/en",
    "routePrefix": "en",
    "regex": "\\/en\\b",
    "description": "English"
  }
};

const browserLanguages = {
  "uk": "ua",
  "en": "en",
  "ru-RU": "ru",
  "en-US": "en",
  "uk-UA": "ua"
};

const siteLanguages = {
  UKRAINIAN: "ua",
  RUSSIAN: "ru",
  UKRAINIAN_FOR_DATEPICKER: "uk",
  ENGLISH: "en"
};

const userAgentType = {
  FIREFOX: "firefox"
};

const cryptoNetworks = [
  "BEP20",
  "LN",
  "OMNI",
  "ERC20",
  "TRC20",
  "SOL",
  "BEP2"
];

const servicesTag = {
  OCT_VISA1: "OCTVISA1",
  OCT_MC1: "OCTMC1",
  OCT2: "OCT2",
  OCT3: "OCT3",
  MS_BANK: "FlashMsBank",
  MS_BANK_2: "MsBank2",
  C2C: "C2C"
};

const siteLanguagesArray = ["en", "ru", "ua"];

const helmetHtmlByLang = {
  "ru": "ru",
  "ua": "uk",
  "en": "en"
};

const creditCardStatuses = {
  VERIFIED: "VERIFIED",
  NOT_VERIFIED: "NOT_VERIFIED",
  CANCELED: "CANCELED",
  PAST_DUE_DATE: "PAST_DUE_DATE",
  DELETED: "DELETED"
};

const feedbackCategoryConst = {
  CARD_VERIFICATION: "CARD_VERIFICATION",
  IBAN_VERIFICATION: "IBAN_VERIFICATION",
  MONEY_BACK: "REFUNDS",
  GENERAL_ISSUES: "GENERAL_ISSUES",
  COOPERATION_OFFER: "COOPERATION_OFFER"
};

const userRoles = {
  ROLE_ANONYMOUS: "anonymous",
  ROLE_CLIENT: "client",
  ROLE_ADMIN: "admin",
  ROLE_MANAGER: "manager",
  ROLE_SEO: "seo",
  ROLE_SUPPORT: "support"
};

const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  HTTP_FORBIDDEN: 403,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409,
  HTTP_UNPROCESSABLE_ENTITY: 422,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404
};

const profileAvatar = {
  MAX_SIZE: 5242880,
  SIZE_MEGABYTES: "5",
  ALLOWED_FORMATS: [
    "image/webp",
    "image/jpeg",
    "image/svg",
    "image/png",
    "image/svg+xml"
  ]
};

const validHeaders = {
  "ffd8ffe0": "image/jpeg",
  "ffd8ffe1": "image/jpeg",
  "ffd8ffe2": "image/jpeg",
  "89504e47": "image/png",
  "52494646": "image/webp",
};

const articleImage = {
  CROPPED: 0,
  ORIGINAL: 1
};

const articleImageType = {
  EDITOR: "editor",
  DETAIL: "detail",
  PREVIEW: "preview"
};

const cancelComment = {
  MIN_LENGTH: 10,
  MAX_LENGTH: 300
};

const dateFilter = {
  MAX_YEAR: 9999,
  MAX_MONTH: 12,
  MAX_DAY: 31
};

const examinationMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

const regex = {
  EMAIL: "^[_a-zA-Z0-9-]+(\\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z-]+)*(\\.[a-zA-Z]{2,10})$",
  REGISTRATION_EMAIL: "^[_a-zA-Z0-9-]+(\\.[_a-zA-Z0-9-]+)*@[a-zA-Z-]+(\\.[a-zA-Z-]+)*(\\.[a-zA-Z]{2,10})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїуєёшщхыърэтьюА-ЯҐІЇУЄЁШЩХЫЪРЭТЬЮ ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїуєёшщхыърэтьюА-ЯҐІЇУЄЁШЩХЫЪРЭТЬЮ0-9]+$",
  CARD: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/g,
  NUMBER: /^\d+[\.|,]?\d+$/,
  DIGITS_PATTERN: "\\d+(?:[.,]\\d+)?",
  REGEX_STANDARD_STATUS: /_/g,
  ALPHABET: /([A-Z])/g,
  SPASE: /\s+/g,
  IBAN_VALUE: /[^\dA-z]/g,
  IBAN_MASK: /(.{4})/g,
  FLOAT: "^\\d*\\.?\\d*$",
  UUID_FROM_8_SYMBOLS: "^.{8}.*$",
  FLOAT_VALUE_WRITE: /\D*(\d+\.?\d{0,8})?.*/g,
  INT_VALUE_WRITE: /\D*(\d+)?.*/g,
  ONE_ELEMENT: "$1",
  FLOAT_VALUE_COMPLETE: /^\d+(\.\d+)?$/,
  EXAMINATION_BASE64: /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~])[a-zA-Z\d!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,50}$/,
  CYRILLIC_SYMBOLS: /[а-яА-ЯЁёҐґІіЇїЄє]/u,
  MEMO_TAG: "^[a-z0-9]{1,32}$",
  CARD_HOLDER_DBO: "/^(0[1-9]|[12][0-9]|3[01]).(0?[1-9]|1[012]).\\d{4}$/"
};

const calcAttributesPlaceholder = {
  Receiver_BIRTH_DAY: "DD.MM.YYYY",
  CARD_HOLDER_DBO: "25.12.2000"
}

const note = "note";

const direction = {
  PAYMENT: "payment",
  PAYOUT: "payout",
  EXCHANGE: "exchange",
  CASH: "CASH"
};

const roles = {
  CLIENT: "ROLE_CLIENT",
  ADMIN: "ROLE_ADMIN",
  MANAGER: "ROLE_MANAGER",
  SEO: "ROLE_SEO",
  SUPPORT: "ROLE_SUPPORT"
};

const descRoles = {
  CLIENT: "client",
  ADMIN: "admin",
  MANAGER: "manager",
  SEO: "seo",
  SUPPORT: "support",
  ANONYMOUS: "anonymous"
};

const invoiceTags = {
  CURRENCY: "CURRENCY",
  CRYPTO: "CRYPTO"
};

const invoiceStatusConst = {
  NEW: "NEW",
  FAIL: "FAIL"
};

const cardVerification = {
  PHOTO_LIMIT: 5
};

const feedbackAuthor = {
  CLIENT: "client"
};

const templatesType = {
  CREDIT: "credit",
  IBAN: "iban",
  FEEDBACK: "feedback"
};

const assets = {
  USDT: "USDT",
  USD: "USD",
  SHIB: "SHIB",
  EUR:"EUR"
};

const cashbackLevel = {
  FIRST: 1
};

const FILTER_FIELD_TO_REFORMATE_DATE = [
  "end_date_gte",
  "end_date_lte",
  "date_gte",
  "date_lte",
  "rdate_gte",
  "rdate_lte",
  "sdate_gte",
  "sdate_lte"
];

const arrayElements = {
  ZERO: 0,
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4
};

const timeoutConst = {
  FIAT: 600,
  CRYPTO: 700
};

const competitor = {
  OFF: 0,
  FIRST: 1,
  SECOND: 2,
  FIFTH: 5,
  TENTH: 10
};

const requisitionAttributes = {
  CARD_NUMBER: "cardNumber",
  CARD_COUNTRY: "cardCountry",
  CARD_HOLDER: "cardHolder",
  RECIPIENT_NAME: "recipientName",
  CARD_COUNTRY_DOB: "cardHolderDOB",
  CARD_COUNTRY_EXP_MONTH: "cardExpMonth",
  CARD_COUNTRY_EXP_YEAR: "cardExpYear",
  CONTACTS: "contacts",
  TAG: "tag",
  WALLET: "wallet",
  PURSE: "purse",
  EMAIL: "email",
  ACCOUNT_PURSE: "accountPurse",
  TYPE_HIDDEN: "hidden",
  IBAN: "iban",
  PAYER_CARD_NUMBER: "payerCardNumber",
  RECIPIENT: "recipient",
  RECIPIENT_ADDRESS: "recipientAddress",
  SWIFT_CODE: "swiftCode",
  RECEIVER_BIRTH_DATE: "receiverBirthDate",
  RECEIVER_COUNTRY: "receiverCountry",
  RECEIVER_FIO: "receiverFio",
  CARD_EXPIRED: "cardExpire",
  PHONE_NUMBER: "phoneNumber",
  RECEIVER_CITY: "receiverCity"
};

const requisitionsAttributesValues = {
  MAX_NAME_LENGTH: 50,
  MAX_NAME_WORDS_AMOUNT: 2
};

const defaultItemsPerPage = 50;

const competitorStatus = (number) => {
  switch (number) {
    case competitor.OFF: {
      return "off";
    }
    case competitor.FIRST: {
      return "first";
    }
    case competitor.SECOND: {
      return "second";
    }
    case competitor.FIFTH: {
      return "fifth";
    }
    case competitor.TENTH: {
      return "tenth";
    }
  }
};

const bank = "bank";

const digitsAfterComma = {
  TWO: 2
};

const clientStatusConst = {
  TRUSTED: "trusted",
  SUSPICIOUS: "suspicious",
  STABLE: "stable"
};

const arrayOfRuEmailsServices = [
  "mail.ru",
  "yandex.ru",
  "rambler.ru",
  "ya.ru",
  "vk.com"
];

const pairColum = {
  PERCENT: "percent",
  IS_ACTIVE: "isActive",
  TOP: "top",
  COMPETITOR: "competitor",
  FREQUENCY: "exchangeFrequency",
  MIN_PERCENT: "minPercent"
};

const mediaObjects = {
  MAX_AMOUNT: 10,
  EMPTY_SIZE: 0,
  MAX_SIZE: 5242880,
  ACCEPTED_TYPES: ["jpg", "png", "jpeg"]
};

const acceptableFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png"
];

const limits = {
  MAX_PHOTO_SIZE_MB: 5,
  CARD_VERIFICATION_PHOTO_LIMIT: 5,
  DOCUMENT_VERIFICATION_LIMIT: 8
};

const fileValidations = {
  SIZE: 5 * 1024 * 1024,
  SIZE_ERROR: "File size should not exceed 5MB!",
  AMOUNT_ERROR: "Max files amount is: 5",
  MAX_AMOUNT: 5,
  EMPTY: 0,
  ONE: 1,
  FORMATS: [
    "image/png",
    "image/jpeg",
    "application/pdf"
  ],
  FORMATS_ERROR: "Only PNG, JPEG, PDF, TXT, DOCX, DOC, XLSX, CSV, and XML files are allowed!"
};

const acceptType = ['image/png', 'image/jpg', 'image/jpeg'];
const fileValidationsFeedback = {
  SIZE: 5 * 1024 * 1024,
  MAX_AMOUNT: 5,
  EMPTY: 0,
  ONE: 1,
  FORMATS: [
    "image/png",
    "image/jpeg",
    "application/pdf"
  ]
};

const validFeedbackHeaders = {
  "ffd8ffe0": "image/jpeg",
  "ffd8ffe1": "image/jpeg",
  "ffd8ffe2": "image/jpeg",
  "89504e47": "image/png",
  "25504446": "application/pdf"
};

const codeLengths = {
  AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
  CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, FI: 18,
  FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21, HU: 28,
  IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21,
  LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31,
  MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24,
  RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26, AL: 28,
  BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
  TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
};

const isMobileDevie = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

const messengers = {
  VIBER: "Viber",
  TELEGRAM: "Telegram"
};

const sortDirection = {
  ASC: "ASC",
  DESC: "DESC"
};

const sortTypes = {
  AXIOS: "axios",
  GRAPHQL: "graphql"
};

const twaFactorAuth = {
  MAX_LENGTH: 6
};

const mailing = {
  MAILING_LANGUAGE: "mailingLanguage",
  MAILING_LANGUAGE_CLOSABLE: "mailingLanguageChange",
  MAILING_SUBSCRIPTION: "Subscribed",
  MAILING_UNSUBSCRIPTION: "Unsubscribed"
};

const authTypes = {
  BASIC: "basic",
  BASIC_TWO_FA: "basic 2fa",
  GOOGLE: "google",
  GOOGLE_TWO_FA: "google 2fa"
};

const attributesParams = {
  CASH: "CASH",
  MIN: "min",
  MAX: "max",
  HIDDEN: "hidden",
  CITY_ID: "cityId",
  CARD_COUNTRY: "cardCountry",
  CONTACTS: "contacts",
  RECIPIENT: "recipient"
};

const bankDetailsPaymentSystemTag = {
  SEPA: "SEPA",
  CASH: "CASH"
};

const bankDetailsPaymentSystemTagNotEqual = [bankDetailsPaymentSystemTag.CASH];

const managerReportsStatuses = {
  BANK: "bank",
  CASH: "cash",
  BANK_AND_CASH: "bankAndCash",
  ALL: null
};

const userRegistration = {
  EMAIL_LENGTH: 100,
  NAME_LENGTH: 50,
  PASSWORD_LENGTH: 50,
  NAME_LENGTH_MIN: 1,
  PASSWORD_LENGTH_MIN: 8,
  PASSWORD_FIELD: "password"
};

const waitTimer = {
  ZERO: 0,
  MAX: 60,
  CODE_EXPIRED: 600,
  INTERVAL: 1,
  INTERVAL_MS: 1000,
  SECONDS_IN_MINUTE: 60
};

const authCodeLength = 4;

const usdtKeyWordForSeoStatistic = "Sum";
const excludedKeysForSeoStatistic = ["id", "firstname", "lastname"];

const calculatorTabsArray = [
  "coin",
  "bank",
  "cash",
  "payments"
  // "all"
];

const calculatorTabsObject = {
  coin: "coin",
  bank: "bank",
  cash: "cash",
  payments: "payments"
  // all: "all"
};

const pairUnitsMultipleActions = {
  ACTIVITY: "activity",
  CALCULATOR_TAB: "calculatorTab"
};

const calculatorSide = {
  left: "left",
  right: "right"
};

const calculatorDirection = {
  left: "payment",
  right: "payout"
};

const contactTypes = {
  bank: "bank",
  cash: "cash",
  other: "other"
};

const documentVerificationSteps = {
  PHONE_VERIFICATION: "Phone verification step",
  EMAIL_VERIFICATION: "Email verification step",
  QUESTIONNAIRE: "Questionnaire",
  APPLICANT_DATA: "Applicant data",
  IDENTITY: "Identity step",
  IDENTITY2: "2nd Identity step",
  IDENTITY3: "3rd Identity step",
  IDENTITY4: "4th Identity step",
  PROOF_OF_RESIDENCE: "Proof of residence",
  PROOF_OF_RESIDENCE2: "2nd Proof of residence",
  SELFIE: "Selfie step",
  SELFIE2: "2nd Selfie step"
};

const parsedUrlKey = {
  SERVICE_KEY: 0,
  CURRENCY_KEY: 1,
  PAYMENT_SYSTEM_KEY: 2,
  CITY_KEY: 3,
  REQUIRED_LENGTH_TO_PARSE: 2,
  SEPARATOR: "-to-",
  LEFT_PAIR_UNIT_KEY: 0,
  RIGHT_PAIR_UNIT_KEY: 1,
  LIMIT_FOR_RERENDER: 1
};

const REQUISITION_ACTION_ACCESS_ROLES = {
  forbidden: [userRoles.ROLE_CLIENT, userRoles.ROLE_SUPPORT],
  adminDataRead: [userRoles.ROLE_ADMIN, userRoles.ROLE_SUPPORT, userRoles.ROLE_MANAGER],
}

const examinationSupporterLangWithOutMainLangInToPathName = ["en", "ua"];

const bestChangeRegexp = /cur_from=[A-Za-z0-9]*&cur_to=[A-Za-z0-9]*(&city=[A-Z]+)?/g;

const parserExelTime = {
  HALF: 30,
  ONE: 60,
  THREE: 180,
  SIX: 360,
  TWELVE: 720,
  DAY: 1440
};

const parserExelTimeSelect = (number) => {
  switch (number) {
    case parserExelTime.HALF: {
      return "30m";
    }
    case parserExelTime.ONE: {
      return "1h";
    }
    case parserExelTime.THREE: {
      return "3h";
    }
    case parserExelTime.SIX: {
      return "6h";
    }
    case parserExelTime.TWELVE: {
      return "12h";
    }
    case parserExelTime.DAY: {
      return "24h";
    }
  }
};

const exceptionPaymentSystemTab = ["TRC20", "ERC20", "POLYGON", "XCHAIN", "CCHAIN", "BEP2", "BEP20", "OMNI"];

const paymentSystemTags = {
  CRYPTO: "CRYPTO",
  CASH: "CASH"
};

const defaultValueDatePicker = "01.01.1970";

const schemas = {
  KYC: "KYC"
}

const displayHiddenAttributesArray = ["fio"];

const userAuthTypes = {
  LOGIN: "login",
  REGISTRATION: "registration",
}

const requisitionActions = {
  C2C_FORM_SUBMISSION: "C2C form submission",
  INVOICE_CREATION: "Invoice creation"
};

export {
  defaultLanguage,
  supportedLanguages,
  helmetHtmlByLang,
  creditCardStatuses,
  feedbackCategoryConst,
  profileAvatar,
  siteLanguages,
  articleImage,
  articleImageType,
  dateFilter,
  regex,
  note,
  cancelComment,
  userRoles,
  responseStatus,
  direction,
  roles,
  invoiceTags,
  invoiceStatusConst,
  cardVerification,
  feedbackAuthor,
  templatesType,
  assets,
  cryptoNetworks,
  cashbackLevel,
  FILTER_FIELD_TO_REFORMATE_DATE,
  arrayElements,
  timeoutConst,
  competitor,
  siteLanguagesArray,
  competitorStatus,
  bank,
  digitsAfterComma,
  clientStatusConst,
  requisitionAttributes,
  arrayOfRuEmailsServices,
  descRoles,
  pairColum,
  mediaObjects,
  servicesTag,
  browserLanguages,
  requisitionsAttributesValues,
  limits,
  codeLengths,
  fileValidations,
  isMobileDevie,
  messengers,
  sortDirection,
  sortTypes,
  twaFactorAuth,
  mailing,
  authTypes,
  attributesParams,
  defaultItemsPerPage,
  bankDetailsPaymentSystemTag,
  bankDetailsPaymentSystemTagNotEqual,
  managerReportsStatuses,
  userRegistration,
  waitTimer,
  authCodeLength,
  usdtKeyWordForSeoStatistic,
  excludedKeysForSeoStatistic,
  calculatorTabsArray,
  calculatorTabsObject,
  pairUnitsMultipleActions,
  calculatorSide,
  documentVerificationSteps,
  calculatorDirection,
  userAgentType,
  examinationSupporterLangWithOutMainLangInToPathName,
  parsedUrlKey,
  bestChangeRegexp,
  REQUISITION_ACTION_ACCESS_ROLES,
  parserExelTime,
  parserExelTimeSelect,
  examinationMobileDevice,
  contactTypes,
  fileValidationsFeedback,
  validFeedbackHeaders,
  acceptType,
  calcAttributesPlaceholder,
  exceptionPaymentSystemTab,
  paymentSystemTags,
  acceptableFileTypes,
  defaultValueDatePicker,
  schemas,
  displayHiddenAttributesArray,
  userAuthTypes,
  validHeaders,
  requisitionActions,
};