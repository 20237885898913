import styled from "styled-components";

export const StyledPasswordValidatorWrapper = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledPasswordValidatorItem = styled.div`
  padding: 2px 4px;
  color: ${({ theme, isValid }) => (
          isValid !== null ? "#F7FBF9" : theme.text)};
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  background: ${({ theme, isValid }) => (
          isValid !== null ? (isValid
                  ? "linear-gradient(90deg, #21793F 0%, #1BA249 98.26%)"
                  : "linear-gradient(90deg,#C74545 0%,#E93939 98.26%)") : theme.bgElements)};
  cursor: pointer;
`;