import React from "react";

import { StyledDropdownMenu } from "./styled-dropdown-nav";

const InformationMenu = ({ children }) => {
  return (
    <StyledDropdownMenu>
      {children}
    </StyledDropdownMenu>
  );
};

export default InformationMenu;