import React from "react";
import Dropdown from "rc-dropdown";
import NavItem from "./nav-item.component";
import InformationMenu from "./dropdown-items/nav-information-menu";
import { useTranslation } from "react-i18next";

import {
  StyledDropdownMenuItem,
  StyledMenuList,
  StyledNavigation,
} from "./styled-navigation";
import { useMedia } from "../../utils/useMedia";
import NavButton from "../header/navButton/nav-button";
import { ReactComponent as PersonalIcon } from "../../assets/images/personal-new.svg";
import { ReactComponent as BusinessIcon } from "../../assets/images/business.svg";
import BusinessMenu from "./dropdown-items/nav-business-menu";
import AboutMenu from "./dropdown-items/nav-about-menu";

const Nav = ({ theme, toggleTheme, isLoggedIn, light, setLight }) => {
  const { t, ready } = useTranslation(["navigations", "sideBar"], { useSuspense: false });
  const isMobile = useMedia("(max-width: 992px)");

  if (!ready) {
    return null;
  }

  return (
    <>
      {!isMobile && <StyledNavigation id="nav">
        {/*
          To control the states of the garland, add the
           <StyledButtonOn/>
          (Uncomment the code below)
        */}
        {/*<StyledButtonOn
          title={light === true ? t("light.turnOff") : t("light.turnOn")}
          light={light}
          onClick={() => {setLight(!light);}}
        />*/}
        {/*<Toggle*/}
        {/*  theme={theme}*/}
        {/*  toggleTheme={toggleTheme}*/}
        {/*/>*/}
        <React.Fragment>
          <StyledMenuList>
            <NavItem
              to="/"
              exact
              icon="exchange"
              linkTitle={t("exchange")}
              className="desktop-hidden"
            />
            <Dropdown
              getPopupContainer={() => document.getElementById("nav")}
              overlay={
                <InformationMenu>
                  <AboutMenu />
                </InformationMenu>
              }
              trigger={["click"]}
              placement="bottomLeft"
              animation="slide-up"
            >
              <StyledDropdownMenuItem
                data-testid="information-desktop"
                className="menu-item"
                onClick={(event) => event.preventDefault()}
              >
                {t("information.title")} <span className="icon-new-arrow-down button-down" />
              </StyledDropdownMenuItem>
            </Dropdown>
            <NavItem
              to="/news"
              icon="news"
              linkTitle={t("news")}
              dataTestId="news"
            />
            {/* mobile menu item start*/}
            <NavItem
              to="/info"
              icon="info"
              linkTitle={t("information.title")}
              className="information-link"
              dataTestId="information-mobile"
            />
            {/* mobile menu item end*/}
            <NavItem
              to="/contacts"
              icon="contacts"
              linkTitle={t("contacts")}
              dataTestId="contacts"
            />
          </StyledMenuList>
        </React.Fragment>
        <div className="buttons-wrapper">
          <StyledMenuList className="business-links">
            <Dropdown
              getPopupContainer={() => document.getElementById("nav")}
              overlay={
              <InformationMenu>
                <BusinessMenu />
              </InformationMenu>
            }
              trigger={["click"]}
              placement="bottomLeft"
              animation="slide-up"
            >
              <StyledDropdownMenuItem
                className="menu-item business"
                onClick={(event) => event.preventDefault()}
              >
                <BusinessIcon /> {t("business.title")} <span className="icon-new-arrow-down button-down" />
              </StyledDropdownMenuItem>
            </Dropdown>
            <NavButton
              to="/"
              icon={<PersonalIcon />}
              title={t("buttons.personal")}
              ariaLabel={t("buttons.ariaPersonal")}
              className="active"
            />
          </StyledMenuList>
        </div>
      </StyledNavigation>}
    </>
  );
};

export default Nav;