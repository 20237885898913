import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { StyledTwoFaInputAndInsertBtn } from "../../pages/registration/styled-registration";
import { authenticationRequest } from "../../utils/authentication";
import { authTypes, twaFactorAuth, userAgentType } from "../../utils/consts.util";
import CustomLink from "../customLink/CustomLink";
import InputGroupComponent from "../input-group/input-group.component";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import { StyledButton } from "../styles/styled-button";
import { StyledFormText, StyledFormTitle, StyledFormWrapper } from "../styles/styled-form";

const userAgent = window.navigator.userAgent.toLowerCase();

const LoginTwoFaForm = ({
  setAuthenticated,
  setAuthenticationType,
  setIsTwoFaEnabled,
  setLoading,
  userCredentials,
  authenticationType,
  googleTokenId,
  apolloClient,
  history,
  loading,
  t,
  customCallBack = false
}) => {
  const [code, setCode] = useState("");

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (code.length !== twaFactorAuth.MAX_LENGTH) {
      return closableNotificationWithClick(t("login.errors.blankCode"), "error");
    }

    let twoFaCredentials = { code: code };

    if (authenticationType !== authTypes.GOOGLE_TWO_FA) {
      twoFaCredentials = {
        ...twoFaCredentials,
        username: userCredentials.username,
        password: userCredentials.password
      };
    }

    authenticationRequest(
      setIsTwoFaEnabled,
      setAuthenticated,
      setLoading,
      setAuthenticationType,
      twoFaCredentials,
      authenticationType,
      apolloClient,
      history,
      t,
      googleTokenId,
      customCallBack
    );
  };

  // TODO я закоментував оскільки не розумію навіщо нам це робити на цій формі
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const handleInsertTwoFa = () => {
    navigator.clipboard.readText().then(text => {
      const digitMatches = text.match(/\d/g);
      if (digitMatches) {
        const firstSixDigits = digitMatches.slice(0, 6).join("");
        setCode(firstSixDigits);
      } else {
        setCode("");
      }
    });
  }

  const handleChangeInputTwoFa = (e) => {
    const value = e.target.value;
    setCode(value.replace(/\D/g, ""));
  }

  useEffect(() => {
    if (code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  return (
    <StyledFormWrapper
      onSubmit={handleSubmit}
      className={`two-factor-form ${loading && "loading"}`}
    >
      <StyledFormTitle
        as="h3"
        className="two-factor-form__title"
      >
        {t("twoFactorAuth.form.title")}
      </StyledFormTitle>
      <StyledTwoFaInputAndInsertBtn>
        <InputGroupComponent
          id="google-auth"
          autoFocus
          type="text"
          autocomplete="off"
          label={t("twoFactorAuth.form.sixDigitCode")}
          value={code}
          handleChange={handleChangeInputTwoFa}
          className="two-factor-form__input moved-label"
          maxLength="6"
          required
        />
        {!userAgent.includes(userAgentType.FIREFOX) &&
          <StyledButton
            type="button"
            onClick={handleInsertTwoFa}
          >
            {t("twoFactorAuth.form.insertBtn")}
          </StyledButton>}
      </StyledTwoFaInputAndInsertBtn>
      <div className="two-factor-form__footer">
        <StyledButton
          type="submit"
          color="main"
          disabled={code.toString().length !== twaFactorAuth.MAX_LENGTH}
        >
          {t("twoFactorAuth.form.button")}
        </StyledButton>
      </div>
      <StyledFormText as="p">
        <Trans components={{ br: <br />, CustomLink: <CustomLink /> }}>
          {t("twoFactorAuth.form.securityCode")}
        </Trans>
      </StyledFormText>
    </StyledFormWrapper>
  );
};

export default LoginTwoFaForm;
