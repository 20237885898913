import React from "react";
import { GoogleLogin } from "react-google-login";

import coloredGoogleIcon from "../../assets/images/icons/google-icon-colored.svg";

import { authenticationRequest } from "../../utils/authentication";
import { authTypes } from "../../utils/consts.util";

import { StyledButton } from "../styles/styled-button";
import { StyledGoogleAuthButton } from "../google-login/styled-google-login";

const LoginGoogleForm = ({
  setAuthenticated,
  setAuthenticationType,
  setGoogleTokenId,
  setIsTwoFaEnabled,
  setLoading,
  userCredentials,
  apolloClient,
  history,
  t,
  customCallBack
}) => {

  const responseGoogle = (response) => {
    if (response.error) {
      setLoading(false);
      return;
    }

    const { tokenId } = response;
    setGoogleTokenId(tokenId);

    authenticationRequest(
      setIsTwoFaEnabled,
      setAuthenticated,
      setLoading,
      setAuthenticationType,
      userCredentials,
      authTypes.GOOGLE,
      apolloClient,
      history,
      t,
      tokenId,
      customCallBack
    );
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={renderProps => (
        <StyledGoogleAuthButton
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={`login-form__google google-btn ${renderProps.disabled ? "disabled" : ""}`}
        >
          <img src={coloredGoogleIcon} alt="Google" width={23} height={23} aria-label="Google" /> {t("registration.form.continueWithGoogle")}
        </StyledGoogleAuthButton>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default LoginGoogleForm;
