import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../customLink/CustomLink";

const FooterCryptoLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <div className="links-wrapper__item">
      <div className="links-wrapper__item__title">
        {ready && t("linkAboutCrypto")}
      </div>
      <ul className="links-wrapper__item__list">
        <li>
          <CustomLink to="/crypto-dictionary">
            {ready && t("cryptoDictionary")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/all-exchange-pairs">
            {ready && t("exchangePairs")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/bitcoin-price">
            {ready && t("kursBitcoina")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/cryptocurrency-exchange">
            {ready && t("kupitKriptovalyutu")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/buy-cryptocurrency">
            {ready && t("obmenKriptovalut")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/bitcoin-wallet">
            {ready && t("bitcoinKoshelek")}
          </CustomLink>
        </li>
        <li>
          <CustomLink to="/exchange-usdt">
            {ready && t("exchangeUSDT")}
          </CustomLink>
        </li>
      </ul>
    </div>
  );
};

export default FooterCryptoLinks;