import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../customLink/CustomLink";

const FooterInfoLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <div className="links-wrapper__item">
        <div className="links-wrapper__item__title">
          {ready && t("linkInfoTitle")}
        </div>
        <ul className="links-wrapper__item__list">
          <li>
            <CustomLink to="/about-us">
              {ready && t("linkInfoAbout")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/rates">
              {ready && t("linkInfoCourse")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/reviews">
              {ready && t("linkInfoReviews")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/partners">
              {ready && t("linkInfoPartners")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/client-manual">
              {ready && t("linkInfoManual")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/faq">
              {ready && t("linkInfoFaq")}
            </CustomLink>
          </li>
        </ul>
    </div>
  );
};

export default FooterInfoLinks;