import React from "react";
import NavInfoMenuItem from "./nav-information-menu-item";
import { useTranslation } from "react-i18next";

import { StyledInfoMenuList, StyledInfoMenuWrapper } from "./styled-dropdown-nav";

const AboutMenu = ({className}) => {
  const { t } = useTranslation("navigations");

  return (
    <StyledInfoMenuWrapper className="info-menu">
      <StyledInfoMenuList className={className ? className : ""}>
        <NavInfoMenuItem
          to="/rates"
          icon=""
          linkTitle={t("information.rates")}
          rel="canonical"
          /* TODO: uncomment after full integration of Wordpress news */
          // target="_blank"
        />
        <NavInfoMenuItem
          to="/reviews"
          icon=""
          linkTitle={t("information.reviews")}
        />
        <NavInfoMenuItem
          to="/about-us"
          icon=""
          linkTitle={t("information.aboutUs")}
        />
        <NavInfoMenuItem
          to="/partners"
          icon=""
          linkTitle={t("information.partners")}
        />
        <NavInfoMenuItem
          to="/cities"
          icon=""
          linkTitle={t("information.cities")}
          rel="canonical"
        />
        <NavInfoMenuItem
          to="/network-confirmations"
          icon=""
          linkTitle={t("information.networkConfirmations")}
        />
        <NavInfoMenuItem
          to="/bounty-programma"
          icon=""
          linkTitle={t("information.bountyProgram")}
        />
      </StyledInfoMenuList>
    </StyledInfoMenuWrapper>
  );
};

export default AboutMenu;