import punycode from "punycode";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { authenticationRequest } from "../../utils/authentication";
import { authTypes, regex, userRegistration } from "../../utils/consts.util";
import CustomLink from "../customLink/CustomLink";
import InputGroupComponent from "../input-group/input-group.component";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import { StyledButton } from "../styles/styled-button";
import { StyledFormText } from "../styles/styled-form";

const LoginForm = ({
  setAuthenticated,
  setUserCredentials,
  setAuthenticationType,
  setIsTwoFaEnabled,
  setLoading,
  apolloClient,
  history,
  t
}) => {

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!password.trim().length || !email.trim().length) {
      return closableNotificationWithClick(t("login.errors.blankForm"), "error");
    }

    if (email.length > userRegistration.EMAIL_LENGTH) {
      return closableNotificationWithClick(t("login.errors.tooLongEmail", {
        amount: userRegistration.EMAIL_LENGTH
      }), "error");
    }

    const userCredentials = {
      username: punycode.toUnicode(email.trim()),
      password: password.trim()
    };

    if (!userCredentials.username.match(regex.EMAIL)) {
      return closableNotificationWithClick(t("login.errors.incorrectEmailFormat"), "error");
    }

    setUserCredentials(userCredentials);

    authenticationRequest(
      setIsTwoFaEnabled,
      setAuthenticated,
      setLoading,
      setAuthenticationType,
      userCredentials,
      authTypes.BASIC,
      apolloClient,
      history,
      t
    );
  };

  return (
    <div>
      <InputGroupComponent
        id="login"
        type="text"
        label={t("login.form.inputs.email")}
        name="username"
        autoComplete="off"
        handleChange={(event) => setEmail(event.target.value)}
        required
        className={`${!email ? "down" : "up"} moved-label`}
      />
      <InputGroupComponent
        id="password"
        type="password"
        name="password"
        label={t("login.form.inputs.password")}
        autoComplete="off"
        handleChange={(event) => setPassword(event.target.value)}
        required
        className={`${!password ? "down" : "up"} moved-label`}
      />
      <StyledFormText
        as="p"
        className="login-form__user-agree"
      >
        <Trans components={{ CustomLink: <CustomLink /> }}>
          {t("login.form.agree")}
        </Trans>
      </StyledFormText>
      <div className="login-form__footer">
        <StyledButton
          className="login-form__button registration-link"
          as={CustomLink}
          to="/registration"
        >
          {t("login.form.buttons.signup")}
        </StyledButton>
        <StyledButton
          className="login-form__button"
          color="main"
          type="submit"
          onClick={handleSubmit}
        >
          {t("login.form.buttons.login")}
        </StyledButton>
      </div>
    </div>
  );
};

export default LoginForm;
