import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../customLink/CustomLink";

const FooterDocumentLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });
  
  return (
    <div className="links-wrapper__item">
        <div className="links-wrapper__item__title">
          {ready && t("linkDocsTitle")}
        </div>
        <ul className="links-wrapper__item__list">
          <li>
            <CustomLink to="/useterms">
              {ready && t("linkDocsAgree")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/privacy">
              {ready && t("linkDocsConf")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/kyc-and-aml">
              {ready && t("linkDocsAML")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/exchange-regulations">
              {ready && t("linkDocsReglam")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/cookies-policy">
              {ready && t("linkDocsCookie")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/card-verification-manual">
              {ready && t("linkDocsVerify")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/not-served-countries">
              {ready && t("notServedCountries")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/not-served-services">
              {ready && t("notServedServices")}
            </CustomLink>
          </li>

          {/*Закоментовано згідно таски https://trello.com/c/1RIUgPFI*/}
          {/*<li>*/}
          {/*  <CustomLink to="/iban-verification-manual">*/}
          {/*    {ready && t("IBAN_verification")}*/}
          {/*  </CustomLink>*/}
          {/*</li>*/}
        </ul>
    </div>
  );
};

export default FooterDocumentLinks;