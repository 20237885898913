import React, { useEffect, useState } from "react";
import CustomLink from "../customLink/CustomLink";
import Cookies from "js-cookie";
import { Trans, useTranslation } from "react-i18next";
import Dialog from "rc-dialog";

import {
  StyledCookieMessageAction,
  StyledCookieMessageContainer,
  StyledCookieMessageContent,
  StyledCookieMessageWrapper,
  StyledCookiePreference,
  StyledCookiePreferenceWrapper
} from "./styled-cookie-message";
import { StyledButton } from "../styles/styled-button";
import Switch from "rc-switch";
import eventBus from "../../utils/eventBus.utils";

const CookieMessage = ({ cookie, setCookie }) => {
  const { ready, t } = useTranslation("other", { useSuspense: false });
  const [visible, setVisible] = useState(false);

  const savePreference = () => {
    Cookies.set("useCookiesConsent", JSON.stringify(cookie), { expires: 365 });
    setVisible(false);
  };

  const acceptCookies = () => {
    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'personalization_storage': 'granted'
    });
  };

  const saveAll = () => {
    for (let option in cookie) {
      setCookie((prevState) => ({ ...prevState, option: true }));
    }

    Cookies.set("useCookiesConsent", JSON.stringify(cookie), { expires: 365 });
    setVisible(false);
    acceptCookies();
  };

  useEffect(() => {
    eventBus.on("cookieSettings", () => {
      setVisible(true);
    });

    return () => {
      eventBus.remove("cookieSettings", () => {});
    };
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Dialog
        visible={visible}
        wrapClassName="rc-modal-center cookie-preference-modal"
        closeIcon={false}
        closable={false}
        animation="zoom"
        maskAnimation="fade"
        title={t("cookieMessage.cookiePreferences.title")}
        className="default-modal"
      >
        <div className="default-modal__body-content">
          <StyledCookiePreferenceWrapper>
            <p>
              {t("cookieMessage.cookiePreferences.description")}
            </p>
            <p>
              <Trans components={{ CustomLink: <CustomLink /> }}>
                {t("cookieMessage.links")}
              </Trans>
            </p>
            <StyledCookiePreference>
              <div className="preference-item">
                <div className="preference-item__content">
                  <h5>
                    {t("cookieMessage.cookiePreferences.strictlyNecessary.title")}
                  </h5>
                  <p>
                    {t("cookieMessage.cookiePreferences.strictlyNecessary.description")}
                  </p>
                </div>
                <div className="preference-item__action">
                  <Switch
                    className="default-switch readOnly"
                    name="active"
                    checked={cookie.strictlyNecessary}
                    disabled
                  />
                </div>
              </div>
              <div className="preference-item">
                <div className="preference-item__content">
                  <h5>
                    {t("cookieMessage.cookiePreferences.functional.title")}
                  </h5>
                  <p>
                    {t("cookieMessage.cookiePreferences.functional.description")}
                  </p>
                </div>
                <div className="preference-item__action">
                  <Switch
                    className="default-switch"
                    name="active"
                    checked={cookie.functional}
                    onChange={() => setCookie((prevState) => ({ ...prevState, functional: !cookie.functional }))}
                  />
                </div>
              </div>
              <div className="preference-item">
                <div className="preference-item__content">
                  <h5>
                    {t("cookieMessage.cookiePreferences.performance.title")}
                  </h5>
                  <p>
                    {t("cookieMessage.cookiePreferences.performance.description")}
                  </p>
                </div>
                <div className="preference-item__action">
                  <Switch
                    className="default-switch"
                    name="active"
                    checked={cookie.performance}
                    onChange={() => setCookie((prevState) => ({ ...prevState, performance: !cookie.performance }))}
                  />
                </div>
              </div>
              <div className="preference-item">
                <div className="preference-item__content">
                  <h5>
                    {t("cookieMessage.cookiePreferences.targeting.title")}
                  </h5>
                  <p>
                    {t("cookieMessage.cookiePreferences.targeting.description")}
                  </p>
                </div>
                <div className="preference-item__action">
                  <Switch
                    className="default-switch"
                    name="active"
                    checked={cookie.targeting}
                    onChange={() => setCookie((prevState) => ({ ...prevState, targeting: !cookie.targeting }))}
                  />
                </div>
              </div>
            </StyledCookiePreference>
          </StyledCookiePreferenceWrapper>
        </div>
        <div className="default-modal__body-footer">
          <StyledButton
            weight="normal"
            onClick={savePreference}
          >
            {t("cookieMessage.cookiePreferences.saveSettings")}
          </StyledButton>
          <StyledButton
            color="main"
            weight="normal"
            onClick={saveAll}
          >
            {t("cookieMessage.agree")}
          </StyledButton>
        </div>
      </Dialog>
      {!cookie.agree &&
        <StyledCookieMessageWrapper>
          <StyledCookieMessageContainer>
            <StyledCookieMessageContent>
              <h4>
                {t("cookieMessage.title")}
              </h4>
              <p>
                {t("cookieMessage.text")}
              </p>
              <Trans components={{ CustomLink: <CustomLink /> }}>
                {t("cookieMessage.links")}
              </Trans>
            </StyledCookieMessageContent>
            <StyledCookieMessageAction>
              <StyledButton
                color="main"
                weight="normal"
                data-testid="Accept-all-cookies"
                onClick={saveAll}
              >
                {t("cookieMessage.agree")}
              </StyledButton>
              <StyledButton
                weight="normal"
                onClick={() => {
                  setVisible(!visible);
                  setCookie((prevState) => ({ ...prevState, agree: true }));
                }}
              >
                {t("cookieMessage.manageCookies")}
              </StyledButton>
            </StyledCookieMessageAction>
          </StyledCookieMessageContainer>
        </StyledCookieMessageWrapper>
      }
    </>
  );
};

export default CookieMessage;
