import styled from "styled-components";

export const StyledGoogleAuthButton = styled.button`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all ease .3s;
  color: ${({theme}) => theme.text };
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.inputBorder };

  &.disabled {
    opacity: 0.4;
    
    &:hover {
      cursor: wait;
    }
  }
    
  &:hover {
    transform: scale(0.98);
    transition: all ease .3s;
    border-color: ${({theme}) => theme.defaultColor};
    box-shadow: 0 8px 12px rgba(78, 78, 78, 0.55);
  }
  &:active {
    transform: scale(0.95);
    transition: all ease .3s;
  }  
`;

export const StyledGoogleAuthContainer= styled.div`
  padding: 4px 13px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({theme}) => theme.inputBorder };
  border-radius: 10px;
  @media screen and (max-width: 576px) {
      justify-content: center;
  }
`;

