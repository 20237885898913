import { StyledMenuSidebarContainer, StyledShowSidebar } from "./styled-sidebar";
import Drawer from "rc-drawer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavItem from "../../navigation/nav-item.component";
import { StyledHeaderLanguageWrapper } from "../styled-header";
import LanguageSelect from "../language/language-select";
import NavButton from "../navButton/nav-button";
import { ReactComponent as PersonalIcon } from "../../../assets/images/personal-new.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/images/business.svg";
import BusinessLinks from "../businessLinks/business-links";

const NavigationSidebar = ({ isLoggedIn, fixed, visible, setVisible }) => {
  const { t, ready } = useTranslation(["navigations", "sideBar"], { useSuspense: false });
  const [openMenu, setOpenMenu] = useState(false);
  const showDrawer = () => {
    setVisible((prev) => !prev);
  };

  const onClose = () => {
    setVisible(false);
  };

  const toggleOpenMenu = () => {
    setOpenMenu(prev => !prev);
  };

  useEffect(() => {
    const header = document.querySelector(".header__wrapper");
    const menuBlock = document.querySelector(".mobile-sidebar");

    if (header && menuBlock) {
      const updateMargin = () => {
        const newHeight = 55;
        menuBlock.style.top = `${newHeight}px`;
      };

      const resizeObserver = new ResizeObserver(updateMargin);
      resizeObserver.observe(header);

      updateMargin();
    }
  }, [visible]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <StyledShowSidebar
        className="sidebar-btn"
        onClick={showDrawer}
        position="left"
        visible={visible}
      >
        <div className="burger-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </StyledShowSidebar>
      <Drawer
        width="256"
        placement="left"
        handler={false}
        open={visible}
        onClose={onClose}
        className={`mobile-sidebar ${!!fixed && "fixed"}`}
      >
        <div className="buttons-wrapper">
          <NavButton
            to="/"
            icon={<PersonalIcon />}
            title={t("buttons.personal")}
            ariaLabel={t("buttons.ariaPersonal")}
            className="active"
          />
          <NavButton
            as="div"
            icon={<BusinessIcon />}
            title={t("buttons.business")}
            items={<BusinessLinks t={t}/>}
            toggleOpenMenu={toggleOpenMenu}
            className={`with-items ${openMenu ? "open" : ""}`}
          />
        </div>
        <StyledMenuSidebarContainer>
          <NavItem
            to="/"
            exact
            icon="exchange"
            linkTitle={t("exchange")}
            onClick={onClose}
          />
          <NavItem
            to="/info"
            icon="info"
            linkTitle={t("information.title")}
            className="information-link"
            dataTestId="information-mobile"
            onClick={onClose}
          />
          <NavItem
            to="/news"
            icon="news"
            linkTitle={t("news")}
            dataTestId="news"
            onClick={onClose}
          />
          <NavItem
            to="/contacts"
            icon="contacts"
            linkTitle={t("contacts")}
            dataTestId="contacts"
            onClick={onClose}
          />
          {!isLoggedIn ? <NavItem
              to="/login"
              icon="log-in"
              linkTitle={t("login")}
              className="login-link"
              dataTestId="login-link"
              onClick={onClose}
            /> :
            <NavItem
              to="/panel/account"
              icon="user-profile"
              linkTitle={t("account")}
              className="account-link"
              dataTestId="account-link"
              onClick={onClose}
            />}
          <StyledHeaderLanguageWrapper className={`menu-item`}>
            <div className="menu-link__icon">
              <span className={`icon-language`} />
            </div>
            <div className="menu-link__title">
              <LanguageSelect direction="ltl" />
            </div>
          </StyledHeaderLanguageWrapper>
        </StyledMenuSidebarContainer>
      </Drawer>
    </>
  );
};

export default NavigationSidebar;