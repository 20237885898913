import styled from "styled-components";

export const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  transition: all .3s ease;
  ${({ fixed, theme }) => fixed && `
    .header__content {
      height: 55px;
      transition: all .2s ease;
      
      .rc-dropdown-placement-bottomCenter,
      .rc-dropdown-placement-bottomLeft {
        top: 54px !important;
      }
    }
    .header__wrapper {
      background-color: ${theme.body};
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    }
  `};
  @media screen and (max-width: 992px) {
    transition: all .2s ease;
    background-color: ${({ theme }) => theme.body};
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .header__extop-banner {
    display: flex;
    justify-content: center;
    gap: 16px;
    background-color: #1BA27A;
    width: 100%;
    padding-top: 8px;
    transition: all .3s ease;

    @media screen and (max-width: 992px) {
      padding: 4px 16px 0 16px;
      gap: 0;
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      align-self: center;
      padding-bottom: 8px;
      transition: all .3s ease;

      span {
        font-weight: 400;
      }

      @media screen and (max-width: 992px) {
        padding-bottom: 4px;
        font-size: 12px;
      }
    }

    &__link {
      margin: 0 auto;
      width: 100%;
      max-width: 1140px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: 992px) {
        padding: 0;
      }
    }

    &__image {
      transition: all .3s ease;

      width: 85px;
      height: 50px;
      align-self: flex-end;
      @media screen and (max-width: 992px) {
        width: 60px;
        min-width: 60px;
        height: 40px;
      }
    }
  }

  .header__extop-banner.fixed {
    padding-top: 4px;

    .header__extop-banner__title {
      font-size: 16px;
      padding-bottom: 4px;
      @media screen and (max-width: 992px) {
        font-size: 12px;
      }
    }

    .header__extop-banner__image {
      width: 71px;
      height: 40px;
    }
  }
`;

export const StyledHeaderContent = styled.div`
  max-width: 1140px;
  width: 100%;
  height: 84px;
  margin: 0 auto;
  padding: 0 15px;

  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all .3s ease;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0;
  align-content: center;

  @media screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    justify-items: center;
    align-content: center;
    height: 55px;
    ${({ fixed, theme }) => fixed && `
        height: 55px;
      `};
    border-bottom: 1px solid ${({ theme }) => theme.defaultColor};
  }
  
  .logo {
    margin-right: 60px;
    width: 164px;
    
    @media screen and (max-width: 1200px) {
      margin-right: 40px;
    }
    @media screen and (max-width: 992px) {
      width: unset;
      margin-right: 0;
    }
  }
`;

export const StyledHeaderLanguageWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    display: flex;
    padding: 13px 20px;
    .rc-select-selection-item {
      font-size: 18px !important;
    }

    .lang-select:hover {
      color: ${({ theme }) => theme.defaultColor} !important;
    }

    .rc-select-arrow {
      top: 3px;
      right: 0 !important;
    }

    .lang-select__item {
    }

    .rc-select-dropdown {
      border: none;
      border-radius: 0;
      left: 18px !important;
    }
  }
`;