import React, { useState } from "react";
import FooterInfoLinks from "./footer-info-links";
import FooterDocumentLinks from "./footer-document-links";

import { StyledContainer } from "../styles/styled-container";
import { StyledFooter, StyledFooterContent } from "./styled-footer";
import CookieMessage from "../cookie-message/cookie-message.component";
import Cookies from "js-cookie";
import FooterComponentContact from "./footer.component-contact";
import FooterComponentAuthor from "./footer.component-author";
import FooterComponentAbout from "./footer.component-about";
import FooterCryptoLinks from "./footer-crypto-links";

const Footer = ({ theme }) => {
  const initialCookies = {
    strictlyNecessary: true,
    functional: false,
    performance: false,
    targeting: false,
    agree: false
  };
  const [cookie, setCookie] = useState(initialCookies);

  return (
    <StyledFooter>
      {!Cookies.get("useCookiesConsent") && <CookieMessage cookie={cookie} setCookie={setCookie} />}
      <StyledContainer wrapper="content">
        <StyledFooterContent>
          <FooterComponentAbout />
          <div className="links-wrapper">
            <div className="links-block-wrapper">
              <FooterCryptoLinks />
              <FooterInfoLinks />
              <FooterDocumentLinks />
              <FooterComponentContact theme={theme} />
            </div>
          </div>
        </StyledFooterContent>
        <FooterComponentAuthor />
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;