import React from "react";

import { StyledSubTitle, StyledTitle, StyledTitleDescription, StyledTitleWrapper } from "./styled-title";

const Title = ({ as, className, title, subtitle, style }) => {
  return (
    <StyledTitleWrapper className={className} style={style}>
      {subtitle && <StyledSubTitle>
        {subtitle}
      </StyledSubTitle>}
      <StyledTitle as={as}>
        {title}
      </StyledTitle>
     {/* {description &&
      <StyledTitleDescription>
        {description}
      </StyledTitleDescription>}*/}
    </StyledTitleWrapper>
  );
};
export default Title;