import styled from "styled-components";

const colorButton = {
    color: String,
}

const styledSize = (size) => {
    switch (size) {
        case 'small':
            return `
                padding: 4px 8px;
                font-size: 12px;
            `;
        default:
            return ``;
    }
}

const styleButton = (color) => {
    switch (color) {
        case 'main':
            return `
                padding: 12px 32px;
                color: rgba(15, 15, 15, 1);
                background: rgba(238, 204, 65, 1);
                border: none;
                &:hover, &:active {
                  background: rgba(255, 200, 5, 1);
                }
            `;
        case 'success':
            return `
                padding: 12px 32px;
                color: #fff;
                background: #1BA249;
                border: none;
                &:hover,  &:active {
                  background: #1BA24980;
                }
            `;
        case 'danger':
            return `
                padding: 12px 32px;
                color: #fff;
                background: #E93939;
                border: none;
                &:hover, &:active {
                  background: #E9393980;
                }
            `;
        case 'warning':
            return `
                padding: 12px 32px;
                color: #202020;
                background: #F2B43C 100%;
                border: none;
                &:hover, &:active {
                  background: #F2B43C80;
                }
            `;
        case 'info':
            return `
                padding: 12px 32px;
                color: #fff;
                background: #29579D;
                border: none;
                &:hover, &:active {
                  background: #29579D80;
                }
            `;
        default:
            return `
                padding: 11px 30px;
                background: transparent;
                border: 1px solid rgba(255, 200, 5, 1);
                
                &:hover, &:active {
                  box-shadow: inset 0 0 0 1px rgba(255, 200, 5, 1);
                  color: rgba(255, 200, 5, 1);
                }
            `;
    }
}

export const StyledButton = styled('button', colorButton)`
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  ${({mr}) => mr && `margin-right: ${mr}px`};
  ${({ml}) => ml && `margin-left: ${ml}px`};
    width: ${({figure}) => figure !== 'circle' ? 'auto' : '46px'};
    height: ${({figure}) => figure !== 'circle' ? 'auto' : '46px'};
    color: ${({theme}) => theme.text};
    font-size: 16px;
    font-weight: ${({weight}) => weight === 'normal' ? '400' : '600'};
    text-align: center;
    vertical-align: middle;
    //text-transform: ${({weight}) => weight === 'normal' ? 'inherit' : 'uppercase'};
    letter-spacing: 0.5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({figure}) => figure === 'circle' ? '50%' : '12px'};
    outline: none;
    cursor: pointer;
    transition: all .1s ease;
    ${({disabled}) => disabled && `
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
        transform: scale(1) !important;
    `};
    ${({color}) => styleButton(color)}
    ${({size}) => styledSize(size)}
    ${({withoutBorder}) => withoutBorder && `border: none`};
    
    //&:hover {
    //  transform: scale(0.98);
    //}
    //&:active {
    //  transform: scale(0.95);
    //}
`;
