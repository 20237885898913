import React from "react";
import CustomLink from "../customLink/CustomLink";

import { StyledMenuItem } from "./styled-navigation";

const NavItem = ({ to, exact, icon, linkTitle, className, onClick, dataTestId = null }) => {
  return (
    <StyledMenuItem className={`menu-item ${className}`} onClick={onClick}>
      <CustomLink to={to} className="menu-link" activeClassName="menu-link_current" exact={exact} dataTestId={dataTestId}>
        {icon && <div className="menu-link__icon">
          <span className={`icon-${icon}`} />
        </div>}
        <div className="menu-link__title">
          {linkTitle}
        </div>
      </CustomLink>
    </StyledMenuItem>
  );
};

export default NavItem;