import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import InputGroupComponent from "../input-group/input-group.component";

import client from "../../client";
import eventBus from "../../utils/eventBus.utils";
import { AuthContext } from "../../App";
import { responseStatus, siteLanguagesArray, twaFactorAuth, userAgentType } from "../../utils/consts.util";
import { Trans, useTranslation } from "react-i18next";

import { StyledFormText, StyledFormTitle, StyledFormWrapper } from "../styles/styled-form";
import { StyledButton } from "../styles/styled-button";
import i18next from "i18next";
import CustomLink from "../customLink/CustomLink";
import { StyledTwoFaInputAndInsertBtn } from "../../pages/registration/styled-registration";
import { authRedirectHandler, redirectHandler } from "../../utils/authentication";

const userAgent = window.navigator.userAgent.toLowerCase();

const TwoFactorAuthForm = ({ loginResponse, loading, setLoading, authHeader }) => {

  const history = useHistory();
  const [code, setCode] = useState("");
  const { setAuthenticated } = useContext(AuthContext);

  const { t } = useTranslation("auth");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (loading) {
      return;
    }

    setLoading(true);

    if (code.toString().length < twaFactorAuth.MAX_LENGTH) {
      setLoading(false);

      return closableNotificationWithClick(t("twoFactorAuth.notification.incCodeAttempts"), "error");
    }

    const data = {
      id: loginResponse.data.id,
      code: code
    };

    axios.post("/api", data, authHeader).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setAuthenticated(true);
        const { token, refresh_token } = response.data;
        const { id, role, managerCity, createdAt, username } = jwt_decode(token);
        if (role === "manager") {
          localStorage.setItem("refresh_token", refresh_token);
        }
        localStorage.setItem("token", token);
        client.writeData(
          { data: { isLoggedIn: true, userId: id, userRole: role, username: username, managerCity, createdAt } });

        eventBus.dispatch("login_user", { "id": id });

        authRedirectHandler(history, id, role);
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_FORBIDDEN) {
        const attempts = error.response.data.attempt;

        if (attempts !== undefined) {
          closableNotificationWithClick(t("twoFactorAuth.notification.incCodeAttempts"), "error");

          return;
        }

        closableNotificationWithClick(error.response.data["hydra:description"], "error");
      }

      if (error.response.status === responseStatus.HTTP_CONFLICT) {
        closableNotificationWithClick(t("twoFactorAuth.notification.ban"), "error");
      }
    }).finally(() => setLoading(false));
  };

  const handleChangeInputTwoFa = (event) => {
    const { value } = event.target;

    setCode(value.replace(/\D/g, ""));
  };

  const handleInsertTwoFa = () => {
    navigator.clipboard.readText().then(text => {
      const digitMatches = text.match(/\d/g);
      if (digitMatches) {
        const firstSixDigits = digitMatches.slice(0, 6).join("");
        setCode(firstSixDigits);
      } else {
        setCode("");
      }
    });
  };

  useEffect(() => {
    if (code.length === twaFactorAuth.MAX_LENGTH) {
      handleSubmit();
    }
  }, [code]);

  return (
    <StyledFormWrapper
      onSubmit={handleSubmit}
      className={`two-factor-form ${loading && "loading"}`}
    >
      <StyledFormTitle
        as="h3"
        className="two-factor-form__title"
      >
        {t("twoFactorAuth.form.title")}
      </StyledFormTitle>
      <StyledTwoFaInputAndInsertBtn>
        <InputGroupComponent
          id="google-auth"
          autoFocus
          type="text"
          autocomplete="off"
          label={t("twoFactorAuth.form.sixDigitCode")}
          value={code}
          handleChange={handleChangeInputTwoFa}
          className="two-factor-form__input"
          maxLength="6"
          required
        />
        {!userAgent.includes(userAgentType.FIREFOX) &&
          <StyledButton
            type="button"
            onClick={handleInsertTwoFa}
          >
            {t("twoFactorAuth.form.insertBtn")}
          </StyledButton>}
      </StyledTwoFaInputAndInsertBtn>
      <div className="two-factor-form__footer">
        <StyledButton
          type="submit"
          color="main"
          disabled={code.toString().length !== twaFactorAuth.MAX_LENGTH}
        >
          {t("twoFactorAuth.form.button")}
        </StyledButton>
      </div>
      <StyledFormText as="p">
        <Trans components={{ br: <br />, CustomLink: <CustomLink /> }}>
          {t("twoFactorAuth.form.securityCode")}
        </Trans>
      </StyledFormText>
    </StyledFormWrapper>
  );
};

export default TwoFactorAuthForm;
