import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/cookies.utils";

import { StyledContainer } from "../../styles/styled-container";

const WidgetSectionComponent = () => {
  const currentLocale = getCookie("i18next");
  const [containerHeight, setContainerHeight] = useState(window.innerWidth <= 768 ? 640 : 500);

  const theme = window.localStorage.getItem("theme");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/extop/exchanger.js";
    script.dataset.locale = currentLocale;
    script.dataset.layout = "horizontal";
    script.dataset.theme = "dark";

    const extopWidget = document.getElementById("extopWidget");

    const observer = new MutationObserver(() => {
      if (extopWidget?.offsetHeight > 0) {
        setContainerHeight("auto");
        observer.disconnect();
      }
    });

    if (extopWidget) {
      observer.observe(extopWidget, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    document.body.appendChild(script);

    return () => {
      observer.disconnect();
      document.body.removeChild(script);
    };
  }, [currentLocale, theme]);

  return (
    <StyledContainer wrapper="content" style={{ height: containerHeight }}>
      <div id="extopWidget" />
    </StyledContainer>
  );
};

export default WidgetSectionComponent;