import styled from "styled-components";

export const StyledShowSidebar = styled.div`
  cursor: pointer;
  ${({ position }) => position === "left" && "justify-self:start;"}
  
  &:hover {
    color: ${({ theme }) => theme.defaultColor};
  }

  .burger-btn {
    position: relative;
    width: 30px;
    height: 20px;

    span {
      position: absolute;
      width: 100%;
      height: 3px;
      background: #fff;
      border-radius: 2px;
      transition: all 0.3s ease;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }
    ${({visible}) => visible && "    span {" +
            "      &:nth-child(1) {" +
            "        transform: translateY(8.5px) rotate(45deg);" +
            "      }" +
            "      &:nth-child(2) {" +
            "        opacity: 0;" +
            "      }" + 
            "      &:nth-child(3) {" +
            "        transform: translateY(-8.5px) rotate(-45deg);" +
            "      }" +
            "    }"}
  }

  .sidebar-btn__icon {
    font-size: 24px;
    display: none;
    @media screen and (max-width: 992px) {
      display: inline-flex;
    }
  }

  .sidebar-btn__title {
    font-size: 16px;
    letter-spacing: 1px;
    width: 110px;
    height: 40px;
    @media screen and (max-width: 992px) {
      display: none;
    }
    font-weight: 600;
    background-color: ${({ theme }) => theme.defaultColor};
    border: 1px solid ${({ theme }) => theme.defaultColor};
    border-radius: 12px;
    color: ${({ theme }) => theme.body};
    transition: all .3s;
    text-align: center;
    padding: 8px 0;
    color: ${({ theme }) => theme.body};

    &:hover {
      transform: scale(0.98);
      color: ${({ theme }) => theme.body};
    }

    &:active {
      color: ${({ theme }) => theme.body};
      transform: scale(0.95);
    }
  }
}
`;

export const StyledSidebar = styled.div`
  height: 100%;
`;

export const StyledAccount = styled.div`
  margin-bottom: 20px;
  padding: 35px 15px 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, .2);
  background-color: ${({ theme }) => theme.bgElements};

  .user {
    width: 100%;
    max-width: 200px;

    &__name {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__email {
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      opacity: 0.5;
    }
  }

  .sidebar-account__settings {
    padding: 5px 0;

    .settings-link {
      font-size: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 0.75;

      &:hover {
        color: ${({ theme }) => theme.defaultColor};
        transition: all .3s ease;
        transform: rotate(180deg);
      }
    }
  }
`;

export const StyledSidebarItem = styled.div`
  padding: 0 15px;

  .sidebar-link {
    color: ${({ theme }) => theme.text};

    &__icon {
      width: 25px;
      font-size: 16px;
      text-align: center;
    }

    &__title {
      padding-left: 10px;
    }

    &:hover {
      color: ${({ theme }) => theme.defaultColor};
      opacity: 1;
    }

    &_current {
      color: ${({ theme }) => theme.defaultColor};
      background-color: ${({ theme }) => theme.body};
      opacity: 1;
    }
  }

  .document-verification {
    .sidebar-link__title {
      max-width: 165px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SidebarLink = styled.button`
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  outline: none;
  opacity: 0.75;
`;

export const StyledLoadingSidebar = styled.div`
  .loading-sidebar {
    position: relative;
    ${({ type }) => type === "error" ? `
        color: #FF5B5B;
        text-decoration: line-through` : null};

    &:hover {
      opacity: 0.55;
      cursor: wait;
    }
  }

  .loading-sidebar__icon {
    width: 21px;
    height: 24px;
    padding-top: 2px;
    position: absolute;
    display: none;

    & > div {
      margin: 0;
    }

    @media screen and (max-width: 992px) {
      display: inline-flex;
      position: static;
    }
  }
`;

export const StyledItemWithBadge = styled.div`
  position: relative;
`;

export const StyledDocumentVerification = styled.div`
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-family: 'theme-icon', serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  background-color: #faad14;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: auto;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-10px);
`;

export const StyledMenuSidebarContainer = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;

  .menu-link {
    padding: 13px 20px;
    display: flex;
    gap: 10px;
    font-size: 14px;
    flex-direction: row;
    color: #fff;

    &_current {
      color: ${({ theme }) => theme.defaultColor};
      background-color: ${({ theme }) => theme.hoverColor};
    }

    &:hover {
      color: ${({ theme }) => theme.defaultColor};
      background-color: ${({ theme }) => theme.hoverColor};
    }

    &__icon {
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      padding: 0;
    }

    &__title {
      font-size: 14px;
    }
  }
`;

export const StyledShowNavMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #ADADAD;
  cursor: pointer;

  &.log-in-btn {
    font-size: 22px;
    color: #adadad;
  }

  .moon-loader {
    width: 22px;
    height: 22px;
  }

  &:hover span {
    color: ${({ theme }) => theme.defaultColor};
  }
`;