import styled from "styled-components";
import { StyledButton } from "../../components/styles/styled-button";

export const StyledRegistrationWrapper = styled.div`
  padding: 20px 0;

  .loading-registration-form {
    width: 100%;
    margin: 0 auto;
  }

  .registration-form {
    text-align: center;

    &__title {
      padding-bottom: 15px;
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width: 576px) {
        display: inline-flex;
        flex-direction: column-reverse;
      }
    }

    &__user-agree {
      font-size: 12px;
    }

    &__button {
      margin: 0 5px;
      @media screen and (max-width: 576px) {
        margin-bottom: 10px;
        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    &__forgot-password {
      padding-top: 15px;
      color: ${({ theme }) => theme.text};
      display: inline-block;
      opacity: 0.5;
    }
  }

  .two-factor-form {
    &__title {
      padding-bottom: 25px;
    }

    &__input input {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 5px;
      text-align: center;
    }

    &__footer {
      padding: 10px 0;
      display: flex;
      justify-content: center;
    }
  }
`;

export const StyledTwoFaInputAndInsertBtn = styled.div`
  position: relative;

  ${StyledButton} {
    padding: 3px 6px;
    color: ${({ theme }) => theme.defaultColor};
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.hoverColor};
    border: 1px solid ${({ theme }) => theme.hoverShadow};
    position: absolute;
    top: 32px;
    right: 5px;
  }

  @media screen and (max-width: 576px) {
    ${StyledButton} {
      display: none;
    }
  }
`;