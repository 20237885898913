import styled from "styled-components";

export const StyledContentWrapper = styled.a`
  padding: 5px 12px;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  color: #B1B1B1;
  font-size: 16px;
  font-weight: 400;
  transition: background-color 0.3s ease, color 0.3s ease;

  @media screen and (max-width: 992px) {
    color: ${({ theme }) => theme.white};
  }

  .btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    @media screen and (max-width: 992px) {
      gap: 10px;
      
      & svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.with-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    gap: 0;
    
    &:hover {
      cursor: pointer;
    }

    ul {
      max-height: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
      
      li {
        width: 100%;
        
        &:first-child {
          margin-top: 12px;
        }
        
        a {
          align-items: flex-start;
          
          div {
            width: 100%;
            padding: 8px 0;
            font-size: 16px !important;
          }
        }
      }
    }
  }

  &.with-items.open ul {
    width: 100%;
    max-height: 300px;
    display: flex;
    opacity: 1;
  }

  &:hover,
  &.active {
    background-color: #191919;
    color: ${({ theme }) => theme.white};

    @media screen and (max-width: 992px) {
      color: ${({ theme }) => theme.defaultColor};
    }
  }

  @media screen and (max-width: 1200px) {
    //padding: 8px 12px;
    gap: 8px;
  }

  @media screen and (max-width: 992px) {
    border-bottom: 1px solid rgba(238, 204, 65, 0.3);
    text-align: left;
    background-color: transparent;
    border-radius: 0;
    padding: 20px;
    justify-content: flex-start;
    font-size: 18px;
    letter-spacing: 1px;
    gap: 10px;

    &:hover,
    &.active {
      background-color: ${({ theme }) => theme.hoverColor};
    }

    &.active {
      color: ${({ theme }) => theme.defaultColor};
      background-color: ${({ theme }) => theme.hoverColor};

      &:hover {
        background-color: ${({ theme }) => theme.hoverColor};
      }
    }
  }

  @media screen and (max-width: 768px) {
    letter-spacing: 0;
  }
`;