import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { AuthContext } from "../../App";
import eventBus from "../../utils/eventBus.utils";
import Nav from "../navigation/nav.component";
import Logo from "../logo/logo.component";
// import ChristmasGarland from "./decorations/christmas-garland";
import IS_LOGGED_IN from "../../graphql/queries/login.query";
import { StyledHeader, StyledHeaderContent, StyledHeaderLanguageWrapper, StyledHeaderWrapper } from "./styled-header";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { defaultLanguage } from "../../utils/consts.util";
import NavigationSidebar from "./sidebar/navigation-sidebar.component";
import { useMedia } from "../../utils/useMedia";
import { StyledProfileNav } from "../navigation/styled-navigation";
import LanguageSelect from "./language/language-select";
import { NavLink } from "react-router-dom";
import { StyledShowNavMenu } from "./sidebar/styled-sidebar";
import ProfileSidebar from "./sidebar/profile-sidebar.component";

const Header = ({ theme, toggleTheme }) => {
  const { t, ready } = useTranslation("other");
  const isMobile = useMedia("(max-width: 992px)");
  const isNarrow = useMedia("(max-width: 768px)");
  // const [light, setLight] = useState(true);
  const [fixed, setFixed] = useState(false);
  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;
  const client = useApolloClient();
  const { setAuthenticated } = useContext(AuthContext);
  const [visibleNavSidebar, setVisibleNavSidebar] = useState(false);

  const {
    data: { isLoggedIn },
  } = useQuery(IS_LOGGED_IN);

  let height = 50;
  let scrolled = 0;
  let ticking = false;

  const fixedHeader = (scrolled) => {
    if (scrolled > height) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener("scroll", function () {
    scrolled = window.pageYOffset;

    if (!ticking) {
      window.requestAnimationFrame(function () {
        fixedHeader(scrolled);
        ticking = false;
      });
      ticking = true;
    }
  });

  useEffect(() => {
    const handleUserLoggedIn = () => {
      const token = localStorage.getItem("token");

      if (token) {
        const { id, role, createdAt, username } = jwt_decode(token);

        client.writeData({ data: { isLoggedIn: true, userId: id, userRole: role, username: username, createdAt } });

        setAuthenticated(true);

        eventBus.dispatch("login_user", { "id": id });
      }
    };

    window.addEventListener("userLoggedIn", handleUserLoggedIn);

    return () => {
      window.removeEventListener("userLoggedIn", handleUserLoggedIn);
    };
  }, []);

  return (
    <>
      {/*
        To add a Christmas garland, add the component and state "light" - <ChristmasGarland light={light} />.
      */}
      {/*<ChristmasGarland light={light} />*/}
      <StyledHeader id="header" fixed={fixed}>
        <StyledHeaderWrapper className="header__wrapper">
          <StyledHeaderContent className="header__content">
            {isMobile &&
              <NavigationSidebar
                isLoggedIn={isLoggedIn}
                fixed={fixed}
                visible={visibleNavSidebar}
                setVisible={setVisibleNavSidebar}
              />
            }

            <Logo fixed={fixed} />
            <Nav
              theme={theme}
              toggleTheme={toggleTheme}
              isLoggedIn={isLoggedIn}
              // light={light}
              // setLight={setLight}
            />
            <StyledProfileNav>
              {!isMobile && <StyledHeaderLanguageWrapper>
                <LanguageSelect/>
              </StyledHeaderLanguageWrapper>}

              {!isLoggedIn ? (
                <NavLink to="login">
                  <StyledShowNavMenu className={!isNarrow ? "log-in-btn" : ""}>
                    <span className="icon-log-in" />
                  </StyledShowNavMenu>
                </NavLink>
              ) : <ProfileSidebar setVisibleNavSidebar={setVisibleNavSidebar}/>}
            </StyledProfileNav>
          </StyledHeaderContent>
        </StyledHeaderWrapper>
      </StyledHeader>
    </>
  );
};

export default Header;
