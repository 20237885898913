import styled from "styled-components";
import { StyledButton } from "../styles/styled-button";

const inputStyleTemplate = (theme) => {
  return `
    width: 100%;
    padding: 10px 5px;
    font-size: 16px;
    background-color: transparent;
    border-radius: 0;
    border-bottom: 1px solid ${theme.inputBorder};
    &:hover {
      border-color: ${theme.activeInputBorder};
    }
    &:focus, &:active {
      border-color: ${theme.defaultColor};
      box-shadow: 0 8px 12px rgba(78, 78, 78, 0.55);
    }
    &::placeholder {
      color: ${theme.text};
      opacity: 0.2;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px ${theme.body};
      -webkit-text-fill-color: ${theme.text};
    }
    &:read-only {
      color: ${theme.textReadonly};
    }
    &:read-only:focus{
      box-shadow: none;
      background-color: transparent;
      border-bottom: 1px solid ${theme.defaultColor};
    }
  `;
};

export const StyledInputGroup = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  
  &.input-group.moved-label label,
  &.input-group.moved-label.down label{
    transform: translateY(30px);
    transition: all ease .3s;
    opacity: 1;
  }
  
  &.input-group.moved-label:has(input:focus) label,
  &.input-group.moved-label:has(input:active) label,
  &.input-group.moved-label:has(textarea:focus) label,
  &.input-group.moved-label:has(textarea:active) label,
  &.input-group.moved-label.has-text:has(textarea) label,
  &.moved-label:has(input:not([value=""])) label,
  &.moved-label.down label
  {
    opacity: 0;
  }
  
  ${({type}) => type === 'hidden' && `
    height: 0;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
  `};
`;

export const StyledLabel = styled.label`
  margin-bottom: ${({mb}) => mb ? `${mb}px` : "3px"};
  display: block;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }) => theme.textOpacity};
`;


export const StyledInputWrapper = styled.div`
  display: grid;
  position: relative;
`;

export const StyledTextarea = styled.textarea`
  height: 125px;
  color: ${({theme}) => theme.text};
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  ${({theme}) => inputStyleTemplate(theme)}
`

export const StyledInput = styled.input`
  ${({theme}) => inputStyleTemplate(theme)}
`;

export const StyledDelayInput = styled.div`
  input {
    ${({theme}) => inputStyleTemplate(theme)}
  }
`;
export const StyledNumberInput = styled.div`
  input {
    ${({theme}) => inputStyleTemplate(theme)}
  }
`;
export const StyledMaskInput = styled.div`
  input {
    ${({theme}) => inputStyleTemplate(theme)}
  }
`;

export const StyledSkeletonInput = styled.div`
  width: 100%;
  padding: 11px 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  background-color: transparent;
  border-bottom: 1px solid ${({theme}) => theme.defaultColor};
  span {
    width: 100%;
    display: inline-flex;
    opacity: 0.75;
    cursor: not-allowed;
  }
  & > div {
    margin: 0;
  }
`;

export const StyledPasswordEye = styled.button`
  margin: 0;
  padding: 0;
  color: ${({theme}) => theme.text};
  font-size: 16px;
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  bottom: 8px;
  right: 6px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const StyledError = styled.div`
  padding: 5px 0 0;
  color: #FF5B5B;
  font-size: 12px;
  text-align: left;
`;

export const StyledSkeletonError = styled.div`
  height: 17px;
  margin-bottom: 5px;
  color: #FF5B5B;
  font-size: 12px;
  display: block;

  @media screen and (max-width: 992px) {
    height: unset;
  }
`;

export const StyledCopyInput= styled.div`
  display: grid;
  grid-template-columns: 1fr 84px;
  grid-gap: 15px;
  .copy-input__btn-align {
    padding-bottom: 15px;
    display: inline-grid;
    align-items: end;
  }
`;

export const StyledInputAndInsertField = styled.div`
  position: relative;

  ${StyledButton} {
    padding: 3px 6px;
    color: ${({ theme }) => theme.defaultColor};
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.hoverColor};
    border: 1px solid ${({ theme }) => theme.hoverShadow};
    position: absolute;
    top: 30px;
    right: 5px;
  }

  @media screen and (max-width: 576px) {
    ${StyledButton} {
      display: none;
    }
  }
`;