import styled from 'styled-components';
import { StyledButton } from "../styles/styled-button";

export const StyledRegistrationWrapper = styled.div`
    padding: 20px 0;
    max-width: 560px;
    margin: 0 auto;
    z-index: 10;
    h1, h3 {
        text-align: center;
    }
    
    .registration-form {
        text-align: center;
        &__title {
          margin-bottom: 50px;
          color: ${({ theme }) => theme.text};
          
          @media screen and (max-width: 576px) {
            margin-bottom: 30px;
            text-align: center;
          }
        }
        &__footer {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 24px;
          margin-top: 16px;
          
          .login-link {
            padding: 0;
            color: ${({ theme }) => theme.defaultColor};
            border: none;
            text-decoration: underline;
            
            &:hover {
              box-shadow: none;
              text-decoration: none;
            }
          }
          
          @media screen and (max-width: 576px) {
            gap: 16px;
            display: inline-flex;
            flex-direction: column-reverse;
          }
        }
        &__user-agree {
          font-size: 12px;
          text-align: left;

          a.default-link {
            color: inherit;
            text-decoration: underline;
          }

          a.default-link:hover {
            text-decoration: none;
          }
        }

      &__forgot-password {
        padding-top: 15px;
        color: ${({ theme }) => theme.text};
        display: inline-block;
        opacity: 0.5;
      }
    }

    .two-factor-form {
      &__title {
        padding-bottom: 25px;
      }
      &__input input {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
      }
      &__footer {
        padding: 10px 0;
        display: flex;
        justify-content: center;
      }
    }
`;

export const StyledCheckAuthCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  
  .registration-action {
    text-align: center;
  }
  
  .input-block {
    width: 100%;
    
    label {
      text-align: center;
    }
  }
`;

export const StyledFooterCheckAuthCode = styled.div`
  margin-top: 15px;
  text-align: center;
  
  .auth-code-time {
    padding-top: 10px;
  }
`;

export const StyledSendAgain = styled.div`
  position: relative;
  bottom: 10px;
  right: 0;
  text-align: end;

  ${StyledButton} {
    padding: 3px 6px;
    color: ${({ theme }) => theme.defaultColor};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.hoverColor};
    border: 1px solid ${({ theme }) => theme.hoverShadow};
  }
`;