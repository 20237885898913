import styled from 'styled-components';

export const StyledLoginWrapper = styled.div`
    padding: 20px 0;
    
    h1{
      text-align: center;
    }
    
    .loading-login-form {
      max-width: 560px;
      width: 100%;
      margin: 0 auto;
    }
    .login-form {
        @media screen and (max-width: 576px) {
            text-align: center;
        }
        &__footer {
          display: flex;
          justify-content: space-between;
          gap: 30px;
          margin-top: 16px;

          .registration-link {
            padding: 0;
            color: ${({ theme }) => theme.defaultColor};
            border: none;
            text-decoration: underline;

            &:hover {
              box-shadow: none;
              text-decoration: none;
            }
          }
          @media screen and (max-width: 576px) {
              gap: 16px;
              display: inline-flex;
              flex-direction: column-reverse;
          }
        }
        &__user-agree {
          font-size: 12px;
          text-align: left;

          a.default-link {
            color: inherit;
            text-decoration: underline;
          }

          a.default-link:hover {
            text-decoration: none;
          }
        }
      
      &__forgot-password-wrapper {
        margin-top: 16px;
        text-align: right;

        @media screen and (max-width: 576px) {
          text-align: center;
        }
      }
      
        &__forgot-password {
          color: ${({theme}) => theme.text };
          font-size: 14px;
          display: inline;
          text-decoration: underline;
          margin-top: 12px;
        }
    }
  
    .two-factor-form {
      &__title {
        padding-bottom: 25px;
      }
      &__input input {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
      }
      &__footer {
        padding: 10px 0;
        display: flex;
        justify-content: center;
      }
    }
`;