import styled from "styled-components";

export const StyledReviewsWrapper = styled.div`
  padding: 20px 0;
  .reviews-title {
      margin-bottom: 28px;
    span {
      text-transform: none;
      @media screen and (max-width: 350px) {
        display: none;
      }
    }
      >div{
          text-align: left;
      }
  }
  .hidden-send-reviews-form {
    margin: 0 0 30px;
  }
  .send-reviews-form {
    margin-top: 20px;
    padding: 20px;
    &__title {
      padding-bottom: 15px;
    }
    &__footer {
      display: grid;
      justify-content: end;
    }
  }
`;

export const StyledReviewsBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledReviewsSite = styled.div`

`;
export const StyledReviewsInternet = styled.div`
  .reviews-internet-content {
    margin-top: 20px;
    padding: 15px;
    border: none;
    border-radius: 10px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
    
    ul.alice-carousel__stage {
      display: flex;
      gap: 30px;
      
      li.alice-carousel__stage-item {
        width: 100% !important;
        border-bottom: none;
        border-radius: 15px;
        background-color: #D2D2D2;
        
        .reviews-internet-content__item {
          padding: 30px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        &:last-child {
          border: none;
        }
      }
      @media screen and (min-width: 992px) {
        transform: translate3d(0px, 0px, 0px) !important;
      }
      @media screen and (max-width: 992px) {
        display: inherit;
        li.alice-carousel__stage-item {
          margin-right: 20px;
          border: none;
          width: calc(50% - 10px) !important;
          display: inline-block;
          text-align: center;
        }
      }
      @media screen and (max-width: 768px) {
        display: inherit;
        li.alice-carousel__stage-item {
          width: 100% !important;
          text-align: center;
        }
      }
    }
    
    ul.alice-carousel__dots {
      margin: 0;
      display: none;
      li.alice-carousel__dots-item {
        background-color: ${({ theme }) => theme.defaultColor};
        opacity: 0.4;
      }
      li.__active {
        background-color: ${({ theme }) => theme.defaultColor};
        opacity: 1;
      }
      @media screen and (max-width: 992px) {
        margin-top: 10px;
        display: block;
      }
    }
    .alice-carousel__prev-btn, .alice-carousel__next-btn {
      width: 30px;
      height: 30px;
      margin-top: -27px;
      padding: 0;
      color: ${({theme}) => theme.defaultColor};
      display: none;
      justify-content: center;
      align-items: center;
      background-color: ${({theme}) => theme.hoverColor};
      border: 1px solid ${({theme}) => theme.hoverShadow};
      border-radius: 3px;
      position: absolute;
      top: 50%;
      @media screen and (max-width: 992px) {
        display: flex;
      }
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .alice-carousel__prev-btn {
      left: 0;
    }
    .alice-carousel__next-btn {
      right: 0;
    }
  }
`;

export const StyledReviewListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
    
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap:30px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap:30px;
  }
`

export const StyledReviewsPost = styled.div`
  padding: 32px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${({theme}) => theme.navBarBg};
  border-radius: 20px;
    
  @media screen and (max-width: 992px) {
    padding: 24px 16px;
    gap: 16px;
  }
`;

export const StyledReviewsPostDate = styled.div`
  color: ${({theme}) => theme.darkText};
  line-height: 1.7;
`;

export const StyledReviewsPostContent = styled.div`
  min-height: 58px;
  line-height: 1.5;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
  flex-grow: 1;
  color: ${({ theme }) => theme.textOpacity};
`;

export const StyledReviewsPostAuthor = styled.div`
  color: ${({theme}) => theme.defaultColor};
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  
  span {
    font-size: 32px;
    color: ${({theme}) => theme.inputBorder};
  }
`;

export const StyledReviewsAlert = styled.div`
  margin: 0 0 60px;
  padding: 22px 32px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.navBarBg};
  color: ${({theme}) => theme.textOpacity};

  @media screen and (max-width: 992px) {
    margin: 0 0 32px;
    padding: 20px;
  }
  
  span.icon-bell {
    color: ${({theme}) => theme.defaultColor};
    font-size: 16px;
  }
  
  a {
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
`;