import axios from "axios";
import i18next from "i18next";
import jwt_decode from "jwt-decode";
import { closableNotificationWithClick } from "../components/notification/closable-notification-with-click.component";
import {
  basicAuthenticationConfig,
  basicTwoFaAuthenticationConfig,
  googleAuthenticationConfig,
  googleTwoFaAuthenticationConfig
} from "./authenticationConfig";
import { arrayElements, authTypes, descRoles, responseStatus, roles, siteLanguagesArray } from "./consts.util";
import eventBus from "./eventBus.utils";

const authenticationConfigType = (authenticationType, userCredentials, googleTokenId) => {
  switch (authenticationType) {
    case authTypes.BASIC: {
      authenticationType = basicAuthenticationConfig(userCredentials);
      break;
    }
    case authTypes.BASIC_TWO_FA: {
      authenticationType = basicTwoFaAuthenticationConfig(userCredentials);

      break;
    }
    case authTypes.GOOGLE: {
      authenticationType = googleAuthenticationConfig(googleTokenId);

      break;
    }
    case authTypes.GOOGLE_TWO_FA: {
      authenticationType = googleTwoFaAuthenticationConfig(googleTokenId, userCredentials);

      break;
    }
  }

  return authenticationType;
};

const authRedirectHandler = (history, id, role) => {
  if (role === roles.SEO || role === descRoles.SEO) {
    /* TODO: uncomment after full integration of Wordpress news */
    history.push("/panel/reports");
    // history.push("panel/news");

    return;
  }
  const returnLink = sessionStorage.getItem("link")

  if (returnLink) {
    const linkParts = returnLink.split("/");
    let newLink = null;

    if (linkParts[arrayElements.ZERO] === "") {
      linkParts.splice(arrayElements.ZERO, arrayElements.FIRST);
    }
    if (linkParts[arrayElements.ZERO] && siteLanguagesArray.includes(linkParts[arrayElements.ZERO])) {
      //i18next.changeLanguage(linkParts[arrayElements.ZERO]);
      //window.localStorage.setItem("appLang", linkParts[arrayElements.ZERO]);

      linkParts.splice(arrayElements.ZERO, arrayElements.FIRST);
      newLink = linkParts.join("/");
    }
    sessionStorage.removeItem("link")
    history.push((newLink ?? returnLink).replace(/^\/(en|ua)/, ""));
  }else {
    history.push("/panel/requisitions");
  }
};

const handleAuthentication = (setAuthenticated, authToken, apolloClient, history, customCallBack = false, authenticationType = "basic", eventType = "") => {
  const { id, role, createdAt, username, event } = jwt_decode(authToken);

  localStorage.setItem("token", authToken);
  apolloClient.writeData({ data: { isLoggedIn: true, userId: id, userRole: role, username: username, createdAt } });

  setAuthenticated(true);

  if (customCallBack) {
    customCallBack();
  }

  eventBus.dispatch("login_user", { "id": id });

  window.dataLayer.push({
    event: eventType ? eventType : event,
    method: authenticationType === authTypes.BASIC || authenticationType === authTypes.BASIC_TWO_FA ? "email" : "Google",
    userId: id
  });

  if (history) {
    authRedirectHandler(history, id, role);
  }
};

/**
 * Authentication request for different purposes (login via form, via Google, two-fa login confirm)
 * @param {function} setIsTwoFaEnabled (boolean)
 * @param {function} setAuthenticated (boolean)
 * @param {function} setLoading (boolean)
 * @param {function} setAuthenticationType (basic / Google / basic with two-fa / Google with two-fa)
 * @param {object} userCredentials (username, password, ?code)
 * @param {string} authenticationType (Authentication type)
 * @param {object} apolloClient (useApolloClient)
 * @param {object} history (useHistory)
 * @param {function} t (useTranslation)
 * @param {string|null} googleTokenId (Google token)
 * @param {function|boolean} customCallBack (customCallBack)
 */
const authenticationRequest = (
  setIsTwoFaEnabled,
  setAuthenticated,
  setLoading,
  setAuthenticationType,
  userCredentials,
  authenticationType,
  apolloClient,
  history,
  t,
  googleTokenId = null,
  customCallBack = false
) => {

  setLoading(true);

  const authData = authenticationConfigType(authenticationType, userCredentials, googleTokenId);

  axios.post("/api", authData.body, authData.headers).then((response) => {
    if (response.status === responseStatus.HTTP_OK) {
      if (!response.data.token) {
        const authType = authenticationType === authTypes.BASIC
          ? authTypes.BASIC_TWO_FA
          : authTypes.GOOGLE_TWO_FA;

        setAuthenticationType(authType);
        setIsTwoFaEnabled(true);

        return;
      }

      handleAuthentication(setAuthenticated, response.data.token, apolloClient, history, customCallBack, authenticationType);
    }
  }).catch((error) => {
    if (error?.response?.status === responseStatus.HTTP_FORBIDDEN) {
      if (error.response.data?.attempt) {
        closableNotificationWithClick(t("twoFactorAuth.notification.incCodeAttempts"), "error");

        window.dataLayer.push({
          event: "login_error",
          message: t("twoFactorAuth.notification.incCodeAttempts")
        });

        return;
      }

      if (error.response.data?.detail) {
        closableNotificationWithClick(error.response.data.detail, "error");

        window.dataLayer.push({
          event: "login_error",
          message: error.response.data.detail
        });

        return;
      }

      closableNotificationWithClick(error.response.data["hydra:description"], "error");

      window.dataLayer.push({
        event: "login_error",
        message: error.response.data["hydra:description"]
      });

      return;
    }

    if (error?.response?.status === responseStatus.HTTP_CONFLICT) {
      closableNotificationWithClick(t("twoFactorAuth.notification.ban"), "error");

      window.dataLayer.push({
        event: "login_error",
        message: t("twoFactorAuth.notification.ban")
      });

      return;
    }

    if (error?.response?.status === responseStatus.HTTP_UNAUTHORIZED) {
      closableNotificationWithClick(t("login.errors.incorrect"), "error");

      window.dataLayer.push({
        event: "login_error",
        message: t("login.errors.incorrect")
      });

      return;
    }

    closableNotificationWithClick(error?.response?.data?.detail, "error");

    window.dataLayer.push({
      event: "login_error",
      message: error?.response?.data?.detail
    });
  }).finally(() => setLoading(false));
};

export {
  authenticationRequest,
  handleAuthentication,
  authRedirectHandler,
};