import i18n from "i18next";

import { defaultLanguage } from "../../../utils/consts.util";

import { StyledContentWrapper } from "./styled-nav-btn";

const NavButton = ({ to, title, ariaLabel, icon, className, items, toggleOpenMenu, as }) => {
  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;
  const href = as !== "a" ? undefined : `${language}${to}`;

  return (
    <StyledContentWrapper
      href={href}
      aria-label={ariaLabel}
      className={className}
      onClick={toggleOpenMenu}
      as={as}
    >
      <div className="btn-content">
        {icon}
        <div>{title}</div>
      </div>
      {items && items}
    </StyledContentWrapper>
  );
};

export default NavButton;