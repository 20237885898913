import React from "react";

import NavItem from "../../navigation/nav-item.component";

const BusinessLinks = ({ t }) => {
  return (
    <ul>
      <NavItem
        to="/whitelabel"
        linkTitle={t("business.ownWidget")}
      />
      <NavItem
        to="/additional-services"
        linkTitle={t("business.additionalServices")}
      />
    </ul>
  );
};

export default BusinessLinks;