import { useTranslation } from "react-i18next";
import { StyledInfoMenuList, StyledInfoMenuWrapper } from "./styled-dropdown-nav";
import NavInfoMenuItem from "./nav-information-menu-item";

const BusinessMenu = () => {
  const { t } = useTranslation("navigations");

  return (
    <StyledInfoMenuWrapper>
      <StyledInfoMenuList className="business">
        <NavInfoMenuItem
          to="/whitelabel"
          icon=""
          linkTitle={t("business.ownWidget")}
        />
        <NavInfoMenuItem
          to="/additional-services"
          icon=""
          linkTitle={t("business.additionalServices")}
        />
      </StyledInfoMenuList>
    </StyledInfoMenuWrapper>
  );
};

export default BusinessMenu;