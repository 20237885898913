import styled from "styled-components";
import { StyledSkeletonBg } from "../styles/styled-skeleton-bg";

export const StyledNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  z-index: 99;

  .rc-dropdown-placement-bottomCenter,
  .rc-dropdown-placement-bottomLeft {
    top: 84px !important;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      gap: 10px;
    }
  }
`;

export const StyledProfileNav = styled.nav`
  display: flex;
  gap: 38px;
  justify-self: right;

  @media screen and (max-width: 1200px) {
    gap: 16px;
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    gap: 16px;
  }

  @media screen and (max-width: 1024px) {
    gap: 8px;
  }
  
  &.business-links {
    gap: 16px;

    @media screen and (max-width: 1024px) {
      gap: 8px;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }

  .menu-item {
    padding: 0 10px;
    letter-spacing: 0;

    &:nth-child(2) {
      padding: 0 10px 0 3px;
    }

    &:hover {
      color: ${({ theme }) => theme.defaultColor};
    }

    @media screen and (max-width: 576px) {
      padding: 0;
    }
  }

  .menu-item.desktop-hidden {
    display: none;
    @media screen and (max-width: 992px) {
      display: flex;
    }
  }

{
  padding-right: 0;
}

  .account-link {
    display: none;
    @media screen and (max-width: 992px) {
      display: flex;
    }
  }

  .login-link, .account-link {
    @media screen and (min-width: 992px) {
      font-size: 16px;
      font-weight: 600;
      border: 1px solid ${({ theme }) => theme.text};
      border-radius: 12px;
      color: ${({ theme }) => theme.body};
      transition: all .3s;
      padding: 0;

      &:hover {
        transform: scale(0.98);
        color: ${({ theme }) => theme.defaultColor};
      }
    }
  }

  .information-link {
    display: none;
    @media screen and (max-width: 992px) {
      display: flex;
    }
  }
`;

export const StyledMenuItem = styled.li`
  .menu-link {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_current {
      .menu-link__title {
        color: ${({ theme }) => theme.white};

        &:hover {
          color: ${({ theme }) => theme.defaultColor};
        }
      }
    }

    &__icon {
      padding: 10px 0;
      font-size: 32px;
      text-align: center;
      display: none;
      @media screen and (max-width: 992px) {
        display: flex;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
      @media screen and (max-width: 576px) {
        padding: 5px 0;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 1.5;
      color: ${({ theme }) => theme.white};;

      &:hover {
        color: ${({ theme }) => theme.defaultColor};
      }

      @media screen and (max-width: 992px) {
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 1.34;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: inherit;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }

    &_current {
      .menu-link__icon, .menu-link__title {
        opacity: 1;
      }
    }

    &:hover {
      @media screen and (max-width: 992px) {
        .menu-link__icon, .menu-link__title {
          color: ${({ theme }) => theme.defaultColor};
          opacity: 1;
        }
      }
    }
  }
`;

export const StyledDropdownMenuItem = styled.li`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
  color: ${({ theme }) => theme.textReadonly};

  &.rc-dropdown-open {
    color: ${({ theme }) => theme.white};
  }

  .button-down {
    padding: 1px;
    font-size: 16px;
    //border-radius: 3px;
  }

  &.business {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &:hover {
    color: ${({ theme }) => theme.defaultColor};
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledLoadNavItem = styled(StyledSkeletonBg)`
  ${({ width }) => width ? `width: ${width}px` : "width: 100%"};
  opacity: 0.5;
`;