import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useApolloClient } from "@apollo/react-hooks";
import axios from "axios";

import jwt_decode from "jwt-decode";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import { useTranslation } from "react-i18next";

import eventBus from "../../utils/eventBus.utils";
import { AuthContext } from "../../App";
import { responseStatus, siteLanguagesArray } from "../../utils/consts.util";
import googleAuthenticationConfig from "../../utils/googleAuthenticationConfig";
import i18next from "i18next";
import coloredGoogleIcon from "../../assets/images/icons/google-icon-colored.svg";
import { StyledGoogleAuthButton } from "./styled-google-login";
import { authRedirectHandler } from "../../utils/authentication";

const GoogleAuth = ({ setLoginResponse, setLoading, setAuthHeader = null }) => {

  let history = useHistory();
  const client = useApolloClient();
  const { setAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation("auth");

  const responseGoogle = (response) => {
    if (response.error) {
      setLoading(false);
      return;
    }

    const { tokenId, profileObj: { email } } = response;
    setLoading(true);
    const header = googleAuthenticationConfig(tokenId);

    if (setAuthHeader) {
      setAuthHeader(header);
    }

    axios.post("/api", { email }, header).then((response) => {
      if (!response.data.token && response.status === responseStatus.HTTP_OK) {
        setLoginResponse(response);
      } else if (response.status === responseStatus.HTTP_OK) {
        setAuthenticated(true);
        const { token } = response.data;
        const { id, role, createdAt, username } = jwt_decode(token);
        client.writeData({ data: { isLoggedIn: true, userId: id, userRole: role, username: username, createdAt } });
        localStorage.setItem("token", token);
        eventBus.dispatch("login_user", { "id": id });

        authRedirectHandler(history, id, role);
      } else if (response.status === responseStatus.HTTP_CREATED) {
        closableNotificationWithClick(t("googleAuth.notification.admin"), "error");
      }
      setLoading(false);
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_FORBIDDEN) {
        closableNotificationWithClick(error.response.data["hydra:description"], "error");
      }
      if (error.response.status === responseStatus.HTTP_CONFLICT) {
        closableNotificationWithClick(t("googleAuth.notification.ban"), "error");
      }
      setLoading(false);
    });
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={renderProps => (
        <StyledGoogleAuthButton
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={`${renderProps.disabled ? "disabled" : ""}`}
        >
          <img src={coloredGoogleIcon} alt="Google" width={23} height={23} aria-label="Google" /> {t("registration.form.continueWithGoogle")}
        </StyledGoogleAuthButton>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default GoogleAuth;
