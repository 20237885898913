import React from "react";
import { StyledFooterAuthorContainer } from "./styled-footer";
import { getSynchronizedTime } from "../../utils/serverTime";
import { useTranslation } from "react-i18next";
import FooterMerchants from "./footer-merchants";

const FooterComponentAuthor = ({ theme }) => {
  const currentYear = (getSynchronizedTime().getFullYear());
  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <StyledFooterAuthorContainer>
      <div className="footer__author__wrapper">
        <div className="developed-by">
          <span className="developed-by__label">Developed by:</span>
          <a href="https://exsoft.io/" className="default-link" rel="noreferrer" target="_blank">
            <span className="exsoft-logo-dark"></span>
          </a>
        </div>
        <div className="credits">
          <div className="credits__rights">
            © Сoin24.io, 2018 - {currentYear}<span className="footer-author__year"></span>.{" "}
            {ready && t("rights")}
          </div>
        </div>
      </div>
      <FooterMerchants className="links-wrapper__item__contact__partners"/>
    </StyledFooterAuthorContainer>
  );
};

export default FooterComponentAuthor;