import React from "react";
import { useTranslation } from "react-i18next";
import Logo from '../../assets/images/logo.svg'

const FooterComponentAbout = () => {
  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <div className="about">
      <div className="about__logo">
        <img alt="coin24-logo" src={Logo} width="168"/>
      </div>
    </div>
  )
}

export default FooterComponentAbout;