import styled from "styled-components";
import { StyledContainer } from "../styles/styled-container";

export const StyledFooter = styled.footer`
  padding: 75px 0 51px;
  background-color: ${({ theme }) => theme.navBarBg};
  min-height: 429px;

  .footer-author {
    min-height: 19px;
    opacity: 1;
  }

  @media screen and (max-width: 992px) {
    ${StyledContainer} {
      padding-top: 0;
    }

    padding: 50px 0 130px;
  }
`;

export const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  .about {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 240px;

    @media screen and (max-width: 992px) {
      gap: 16px;
      align-items: center;

      &__heading {
        text-align: center;
      }
    }

    &__heading {
      font-weight: 500;
      font-size: 14px;
    }

    &__our-social {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      a {
        color: black;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        @media screen and (max-width: 450px) {
          width: 32px;
          height: 32px;
          margin-top: 16px;
        }

        svg {
          width: 24px;
          height: 24px;
          color: #ADADAD;
        }

        &:hover {
          border: 1px solid ${({ theme }) => theme.defaultColor};

          svg {
            color: ${({ theme }) => theme.defaultColor};
          }
        }
      }
    }

  }

  .links-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .links-block-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(145px, max-content)) 3fr 1fr;
      gap: 98px;

      @media screen and (max-width: 1200px) {
        gap: 72px;
      }
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
      }
      @media screen and (max-width: 768px) {
        gap: 24px;
      }
      @media screen and (max-width: 450px) {
        grid-template-columns: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto;
      gap: 30px;
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 24px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 480px) {
        text-align: center;
      }

      &__title {
        font-size: 16px;
        font-weight: 600;
      }

      &__list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width: 450px) {
          margin-top: 12px;
        }

        li {
          color: ${({ theme }) => theme.textOpacity};
          font-size: 14px;

          .icon-contact-email,
          .icon-technical-support {
            margin-right: 6px;
          }

          .icon-technical-support {
            font-size: 16px;
          }

          .email {
            padding-top: 6px;
            display: block;
          }

          .working-hours {
            padding-top: 6px;
          }

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        li a:hover {
          color: ${({ theme }) => theme.defaultColor};
        }
      }

      &__contact {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        &__partners {
          display: flex;
          gap: 12px;
          @media screen and (max-width: 992px) {
            margin-top: 16px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
          }
          @media screen and (max-width: 768px) {
            display: flex;
          }
        }
      }
    }
  }
`;

export const StyledFooterAuthorContainer = styled.div`
  margin-top: 32px;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  border-top: 1px solid #1E1E1E;

  @media screen and (max-width: 992px) {
    gap: 16px;
    margin-top: 40px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .footer__author__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .developed-by {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    &__label {
      font-size: 12px;
      font-weight: 400;
      margin-right: 8px;
      color: #838383;
    }
  }

  .credits {
    display: flex;
    gap: 120px;
    align-items: center;

    &__rights {
      color: #9F9F9F;
    }

    @media screen and (max-width: 480px) {
      text-align: center;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }
  }
`;

export const StyledFooterContentItem = styled.div`
  .footer-item__title {
    min-height: 29px;
    padding-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 1;
  }

  .footer-item__list {
    li {
      min-height: 29px;
      padding: 5px 0;
      opacity: 1;

      a {
        &:hover {
          color: ${({ theme }) => theme.defaultColor};
        }
      }
    }
  }

  .social-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 30px);
    grid-gap: 10px;

    li {
      padding: 0;
      opacity: 1;

      a {
        display: block;
        transition: all .3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
`;

export const StyledFooterBlock = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
  @media screen and (max-width: 768px) {
    grid-template-rows: minmax(120px, max-content);
  }
  @media screen and (max-width: 480px) {
    grid-template-rows: max-content;
  }
`;

export const StyledMerchantsWrapper = styled.div`
  display: flex;
  gap: 12px;
  @media screen and (max-width: 992px) {
    //margin-top: 16px;
    //display: grid;
    //grid-template-columns: repeat(2, 1fr);
    //gap: 8px;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

export const StyledDeveloperBy = styled.div`
  margin-top: 20px;
  padding-top: 15px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.borderElements};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;

  p {
    min-height: 22px;
    font-size: 12px;
    padding-top: 5px;
  }
`;

export const StyledMerchantsAndEdgeportBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 25px;
  }
`;