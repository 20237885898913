import styled from 'styled-components';

const type = {
  type: ''
}

const changeType = (type) => {
  switch (type) {
    case 'error' :
      return `
        border-left: 2px solid #ffccc7;
        background-color: rgba(20, 20, 20, 1);
      `;
    case 'success' :
      return `
        border-left: 2px solid #b7eb8f;
        background-color: rgba(20, 20, 20, 1);
      `;
    case 'info' :
      return `
        border-left: 2px solid #91d5ff;
        background-color: rgba(20, 20, 20, 1);
      `;
    default :
      return `
        border-left: 2px solid rgba(255, 200, 5, 1);
        background-color: rgba(20, 20, 20, 1);
      `;
  }
}

export const StyledAlertWrapper = styled('div', type)`
  ${({center}) => center && 'max-width: 1110px'};
  margin: ${({margin}) => margin};
  padding: 15px 15px 15px 32px;
  position: relative;
  word-wrap: anywhere;
  //&:before {
  //  width: 18px;
  //  height: 18px;
  //  color: #fff;
  //  font-size: 10px;
  //  font-family: 'theme-icon', serif;
  //  text-align: center;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  border-radius: 50%;
  //  position: absolute;
  //  top: 15px;
  //  left: 15px;
  //}
  ${({type}) => changeType(type)}
`;