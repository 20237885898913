import axios from "axios";
import Checkbox from "rc-checkbox";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import GoogleAuth from "../../components/google-login/google-login.component";
import TwoFactorAuthForm from "../../components/google-login/two-factor-auth-form.component";
import InputGroupComponent from "../../components/input-group/input-group.component";
import FragmentSpinner from "../../components/spinner/fragment-spinner.component";
import Title from "../../components/title/title.component";
import { helmetHtmlByLang, regex, responseStatus, userRegistration } from "../../utils/consts.util";
import PasswordValidator from "../elements/password/PasswordValidator";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";

import { StyledLoadingWrapper } from "../spinner/styled-spinner";
import { StyledButton } from "../styles/styled-button";
import { StyledContainer } from "../styles/styled-container";
import { StyledAuthFormWrapper, StyledFormText, StyledFormTitle, StyledFormWrapper } from "../styles/styled-form";
import { StyledRegistrationWrapper } from "./styled-registration";
import CustomLink from "../customLink/CustomLink";
import { findAndScrollToTheErrorFirstElement } from "../../utils/customFunk";
import { StyledGoogleAuthContainer } from "../google-login/styled-google-login";
import { useMedia } from "../../utils/useMedia";
import backgroundImage from '../../assets/images/auth-bg-cubes.svg'

export const INITIAL_STATE = { firstname: "", lastname: "", email: "", password: "" };

const RegistrationContainer = () => {
  const isMobile = useMedia("(max-width: 992px)");
  const history = useHistory();
  const { t } = useTranslation("auth");

  const [clientData, setClientDetails] = useState(INITIAL_STATE);

  const [loginResponse, setLoginResponse] = useState(null);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authHeader, setAuthHeader] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState(null);
  const [errors, setErrors] = useState([]);

  const { firstname, lastname, email, password } = clientData;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setClientDetails((prevState) => ({ ...prevState, [name]: value.trim() }));

    if (checkMinValue(name, value.trim())) {
      return;
    }

    setErrors((prevState) => {
      return {
        ...prevState,
        [name]: null
      };
    });
  };

  const handleChangePassword = (event) => {
    const { name, value } = event.target;

    setClientDetails((prevState) => ({ ...prevState, [name]: value }));

    if (checkMinValue(name, value.trim())) {
      return;
    }

    setErrors((prevState) => {
      return {
        ...prevState,
        [name]: null
      };
    });
  };

  const checkMinValue = (field, value, error = false) => {
    let minValue = field === userRegistration.PASSWORD_FIELD ? userRegistration.PASSWORD_LENGTH_MIN : userRegistration.NAME_LENGTH_MIN;

    if (value.trim().length < minValue) {
      let translateField = `form.${field}_field`;

      setErrors((prevState) => {
        return {
          ...prevState,
          [field]: `${t(translateField)} ${t(translateField + "_min")}`
        };
      });

      return true;
    }

    return error;
  };

  const checkAllMinValue = (data, error = false) => {
    let minErrors = error;

    Object.keys(INITIAL_STATE).map((index, value) => {
      minErrors = checkMinValue(index, data[index], minErrors);
    });

    return minErrors;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    if (loading) {
      return;
    }

    let checkError = false;

    let errorFieldName = null;

    if (!firstname.match(regex.NAME) || firstname.length > userRegistration.NAME_LENGTH) {
      setErrors(prev => ({
        ...prev,
        firstname: `${t("form.firstname_field")} ${t("form.error_message", { length: userRegistration.NAME_LENGTH })}`
      }));

      errorFieldName = "firstname";
      checkError = true;
    }

    if (!lastname.match(regex.NAME) || lastname.length > userRegistration.NAME_LENGTH) {
      setErrors(prev => ({
        ...prev,
        lastname: `${t("form.lastname_field")} ${t("form.error_message", { length: userRegistration.NAME_LENGTH })}`
      }));

      errorFieldName = "lastname";
      checkError = true;
    }

    if (!email.match(regex.REGISTRATION_EMAIL) || email.length > userRegistration.EMAIL_LENGTH) {
      setErrors(prev => ({
        ...prev,
        email: `${t("form.email_field")} ${t("form.error_message", { length: userRegistration.EMAIL_LENGTH })}`
      }));

      errorFieldName = "email";
      checkError = true;
    }

    if (!password.match(regex.PASSWORD)) {

      if (password.match(regex.CYRILLIC_SYMBOLS)) {
        closableNotificationWithClick(t("notifications.includesCyrillicSymbols"), "error");
      }

      setErrors(prev => ({
        ...prev,
        password: `${t("form.password_field")} ${t("form.error_message", { length: userRegistration.PASSWORD_LENGTH })}`
      }));

      errorFieldName = "password";
      checkError = true;
    }

    checkError = checkAllMinValue(clientData, checkError);

    if (errorFieldName) {
      findAndScrollToTheErrorFirstElement(`[name=${errorFieldName}]`);
    }

    if (checkError) {
      setLoading(false);

      return;
    }

    let data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password
    };

    axios.post(`/api/users/create-code`, data).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        history.push("/email-verification/" + response.data.id);

        closableNotificationWithClick(response.data.success, "success");
      }
    }).catch(error => {
      closableNotificationWithClick(error?.response?.data["hydra:description"], "error");

      window.dataLayer.push({
        event: "signup_error",
        message: error?.response?.data["hydra:description"]
      });
    }).finally(() => setLoading(false));
  };

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("registration.meta.title")}</title>
        <meta
          name="description"
          content={t("registration.meta.description")}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/registration"}
          hrefLang={helmetHtmlByLang.ru}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua/registration"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/en/registration"}
          hrefLang={helmetHtmlByLang.en}
        />
      </Helmet>
      <StyledRegistrationWrapper>
        <Title
          as="h1"
          title={t("registration.header.title")}
          description={t("registration.header.subTitle")}
          subtitle={t("registration.header.subTitle")}
        />
        <StyledLoadingWrapper className="loading-registration-form">
          <StyledAuthFormWrapper>
            {!isMobile && (<>
              <div className="background-image-left"><img src={backgroundImage} alt={t("registration.images-alt")} /></div>
              <div className="background-image-right"><img src={backgroundImage} alt={t("registration.images-alt")} /></div>
            </>)
            }
            {(loading || load) && <FragmentSpinner position="center" />}
            {loginResponse !== null ?
              <TwoFactorAuthForm
                loading={load}
                setLoading={setLoad}
                loginResponse={loginResponse}
                authHeader={authHeader}
              /> :
              <StyledFormWrapper
                className={`registration-form ${loading && "loading"}`}
                onSubmit={onSubmit}
              >
                <StyledFormTitle
                  as="h3"
                  className="registration-form__title"
                >
                  {t("registration.form.title")}
                </StyledFormTitle>
                  <GoogleAuth
                    setLoading={setLoad}
                    setLoginResponse={setLoginResponse}
                    setAuthHeader={setAuthHeader}
                  />
                <div className="divider">
                  <StyledFormText as="p">
                    {t("registration.form.or")}
                  </StyledFormText>
                </div>
                <InputGroupComponent
                  id="firstname"
                  type="text"
                  label={t("registration.form.inputs.firstname")}
                  name="firstname"
                  value={firstname}
                  handleChange={handleChange}
                  errors={errors}
                  className="moved-label"
                />
                <InputGroupComponent
                  id="lastname"
                  type="text"
                  label={t("registration.form.inputs.lastname")}
                  name="lastname"
                  value={lastname}
                  handleChange={handleChange}
                  errors={errors}
                  className="moved-label"
                />
                <InputGroupComponent
                  id="email"
                  type="text"
                  label={t("registration.form.inputs.email")}
                  name="email"
                  value={email}
                  handleChange={handleChange}
                  errors={errors}
                  className="moved-label"
                />
                <InputGroupComponent
                  id="password"
                  type="password"
                  label={t("form.password_field")}
                  name="password"
                  data-regex={regex.PASSWORD}
                  autoComplete="off"
                  errors={errors || (password !== "" && passwordErrors && Object.values(passwordErrors).includes(false))}
                  onChange={handleChangePassword}
                  debounceTimeout={500}
                  value={password}
                  className="registration-input-group moved-label"
                />
                <PasswordValidator
                  password={password}
                  passwordErrors={passwordErrors}
                  setPasswordErrors={setPasswordErrors}
                  t={t}
                />
                <StyledFormText
                  as="p"
                  className="registration-form__user-agree"
                >
                  <Trans components={{ CustomLink: <CustomLink /> }}>
                    {t("registration.form.agree")}
                  </Trans>
                </StyledFormText>
                <div className="registration-form__footer">
                  <StyledButton
                    className="registration-form__button login-link"
                    as={CustomLink}
                    to="/login"
                  >
                    {t("registration.form.buttons.login")}
                  </StyledButton>
                  <StyledButton
                    className="registration-form__button"
                    color="main"
                    type="submit"
                  >
                    {t("registration.form.buttons.signup")}
                  </StyledButton>
                </div>
              </StyledFormWrapper>
            }
          </StyledAuthFormWrapper>
        </StyledLoadingWrapper>
      </StyledRegistrationWrapper>
    </StyledContainer>
  );
};

export default React.memo(RegistrationContainer);
