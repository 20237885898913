import React from "react";
import CustomLink from "../customLink/CustomLink";
import Coin24Logo from "../../assets/images/logo.svg";
import {
  StyledLogo,
  StyledLogoIcon,
  StyledLogoShadow
} from "./styled-logo";

const Logo = ({ fixed }) => {

  return (
    <CustomLink to="/" className="logo">
      {/*
        To add a Christmas logo, add the "christmas" attribute.
        For example
        <StyledLogo christmas>..
      */}
      <StyledLogo fixed={fixed}>
        <StyledLogoIcon className="logo-icon">
          <img alt="coin24-logo" src={Coin24Logo} width="164" height="58"/>
        </StyledLogoIcon>
      </StyledLogo>
    </CustomLink>
  );
};

export default Logo;