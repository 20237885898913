import styled from 'styled-components';

export const StyledFormWrapper = styled.form`
  ${({hide}) => hide && 'height: 0'};
  ${({hide}) => hide && 'display: none'};
  padding: 55px 80px;
  background: ${({theme}) => theme.bgElements};
  border-radius: 25px;
  ${({hide}) => hide === false ? 'animation: loadContent .15s ease' : 'animation: none'};
  
  @media screen and (max-width: 992px) {
      padding: 30px 20px;
  }
`;

export const StyledFormTitle = styled.div`
  color: ${({theme}) => theme.text};
  font-size: 24px;
  text-align: center;
`;

export const StyledFormText = styled.div`
  padding: 15px 0;
  text-align: center;
`;

export const StyledHiddenForm = styled.div`
  margin: 20px 0;
  grid-template-columns: 1fr;
  display: grid;
`;
export const StyledHiddenFormAction = styled.div`
  display: inline-grid;
  justify-content: start;
`;

export const StyledAuthFormWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  .login-form__title {
    margin-bottom: 50px;

    @media screen and (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
  
  form {
    max-width: 560px;
    width: 560px;
    position: relative;
    padding: 60px 80px;
    background: ${({ theme }) => theme.bgElements};
    z-index: 10;
    border-radius: 25px;

    @media screen and (max-width: 992px) {
      padding: 30px 20px;
    }
    
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  
  .divider {
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:before {
      width: 100%;
      height: 1px;
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      background-color: ${({ theme }) => theme.inputBorder};
      transform: translateY(-50%);
      z-index: 1;
    }
    
    p {
      padding: 0 10px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      display: inline-block;
      z-index: 10;
      position: relative;
      background: ${({ theme }) => theme.bgElements};
      color: ${({ theme }) => theme.dividerTextColor};
    }
  }

  .background-image-left, .background-image-right {
      position: absolute;
      z-index: 1;
  }
  .background-image-left {
      bottom: 0;
      left: -35%;
  }
  .background-image-right {
      top: -50px;
      right: -35%;  
  }
`