import Tooltip from "rc-tooltip";
import React, { useEffect } from "react";
import { regex } from "../../../utils/consts.util";
import { StyledPasswordValidatorItem, StyledPasswordValidatorWrapper } from "./styledPasswordValidator";
import { closableNotificationWithClick } from "../../notification/closable-notification-with-click.component";

const PasswordValidator = ({ password, passwordErrors, setPasswordErrors, t }) => {

  const validate = () => {
    let errors = {
      isLengthValid: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecChar: false
    };

    if (password.length >= 8 && password.length <= 50) {
      errors.isLengthValid = true;
    }

    if (/[A-Z]/.test(password)) {
      errors.hasUppercase = true;
    }

    if (/[a-z]/.test(password)) {
      errors.hasLowercase = true;
    }

    if (/[0-9]/.test(password)) {
      errors.hasNumber = true;
    }

    if (/[!"#$%&'()*+,-.\/:;<=>?@\[\]^_`{|}~]/.test(password)) {
      errors.hasSpecChar = true;
    }

    if (regex.CYRILLIC_SYMBOLS.test(password)) {
      errors.isLengthValid = false;
      closableNotificationWithClick(t("notifications.includesCyrillicSymbols"), "error");
    }

    setPasswordErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [password]);

  return (
    <StyledPasswordValidatorWrapper>
      <StyledPasswordValidatorItem
        data-testid-isLengthValid={!!passwordErrors?.isLengthValid}
        isValid={!password ? null : (!!passwordErrors?.isLengthValid)}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.isLengthValid.description")}
          overlayClassName="password-tooltip"
        >
          <div>
            {t("passwordValidator.isLengthValid.title")}
          </div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        data-testid-hasUppercase={!!passwordErrors?.hasUppercase}
        isValid={!password ? null : (!!passwordErrors?.hasUppercase)}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasUppercase.description")}
          overlayClassName="password-tooltip"
        >
          <div>
            {t("passwordValidator.hasUppercase.title")}
          </div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        data-testid-hasLowercase={!!passwordErrors?.hasLowercase}
        isValid={!password ? null : (!!passwordErrors?.hasLowercase)}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasLowercase.description")}
          overlayClassName="password-tooltip"
        >
          <div>
            {t("passwordValidator.hasLowercase.title")}
          </div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        data-testid-hasNumber={!!passwordErrors?.hasNumber}
        isValid={!password ? null : (!!passwordErrors?.hasNumber)}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasNumber.description")}
          overlayClassName="password-tooltip"
        >
          <div>
            {t("passwordValidator.hasNumber.title")}
          </div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        data-testid-hasSpecChar={!!passwordErrors?.hasSpecChar}
        isValid={!password ? null : (!!passwordErrors?.hasSpecChar)}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasSpecChar.description")}
          overlayClassName="password-tooltip"
        >
          <div>
            {t("passwordValidator.hasSpecChar.title")}
          </div>
        </Tooltip>
      </StyledPasswordValidatorItem>
    </StyledPasswordValidatorWrapper>
  );
};

export default PasswordValidator;