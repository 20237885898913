import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/react-hooks";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import FragmentSpinner from "../../components/spinner/fragment-spinner.component";
import { AuthContext } from "../../App";
import { StyledAuthFormWrapper, StyledFormText, StyledFormTitle, StyledFormWrapper } from "../styles/styled-form";
import { StyledLoadingWrapper } from "../spinner/styled-spinner";
import LoginForm from "./login-form.component";
import LoginTwoFaForm from "./login-two-fa-form.component";
import LoginGoogleForm from "./login-google-form.component";
import { StyledGoogleAuthContainer } from "../google-login/styled-google-login";
import CustomLink from "../customLink/CustomLink";
import { useMedia } from "../../utils/useMedia";
import backgroundImage from '../../assets/images/auth-bg-cubes.svg'

const LoginContainer = () => {
  const { t } = useTranslation("auth");
  const isMobile = useMedia("(max-width: 992px)");

  const { setAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const apolloClient = useApolloClient();

  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userCredentials, setUserCredentials] = useState(null);
  const [authenticationType, setAuthenticationType] = useState(null);
  const [googleTokenId, setGoogleTokenId] = useState(null);

  return (
    <StyledLoadingWrapper className="loading-login-form">
      <StyledAuthFormWrapper>
        {!isMobile && (<>
          <div className="background-image-left"><img src={backgroundImage}  alt={t("login.images-alt")} /></div>
          <div className="background-image-right"><img src={backgroundImage} alt={t("login.images-alt")} /></div>
        </>)
        }
        {loading && <FragmentSpinner position="center" />}
        {!isTwoFaEnabled
          ? <StyledFormWrapper
            className={`login-form ${loading && "loading"}`}
          >
            <StyledFormTitle as="h3" className="login-form__title">{t("login.form.title")}</StyledFormTitle>
            <LoginGoogleForm
              setAuthenticated={setAuthenticated}
              setAuthenticationType={setAuthenticationType}
              setGoogleTokenId={setGoogleTokenId}
              setIsTwoFaEnabled={setIsTwoFaEnabled}
              setLoading={setLoading}
              userCredentials={userCredentials}
              apolloClient={apolloClient}
              history={history}
              t={t}
            />

            <div className="divider">
              <StyledFormText as="p">
                {t("registration.form.or")}
              </StyledFormText>
            </div>
            <LoginForm
              setAuthenticated={setAuthenticated}
              setUserCredentials={setUserCredentials}
              setAuthenticationType={setAuthenticationType}
              setIsTwoFaEnabled={setIsTwoFaEnabled}
              setLoading={setLoading}
              apolloClient={apolloClient}
              history={history}
              t={t}
            />
            <div className="login-form__forgot-password-wrapper">
              <CustomLink
                className="default-link login-form__forgot-password"
                to="/forgot-password"
              >
                {t("login.form.buttons.forgot")}
              </CustomLink>
            </div>
          </StyledFormWrapper>
          : <LoginTwoFaForm
            setAuthenticated={setAuthenticated}
            setAuthenticationType={setAuthenticationType}
            setIsTwoFaEnabled={setIsTwoFaEnabled}
            setLoading={setLoading}
            userCredentials={userCredentials}
            authenticationType={authenticationType}
            googleTokenId={googleTokenId}
            apolloClient={apolloClient}
            history={history}
            loading={loading}
            t={t}
          />
        }
      </StyledAuthFormWrapper>
    </StyledLoadingWrapper>
  );
};

export default LoginContainer;
