import React from "react";
import { Helmet } from "react-helmet-async";
import { helmetHtmlByLang } from "../../utils/consts.util";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "../../components/styles/styled-container";
import LoginContainer from "../../components/login/login-container.component";
import Title from "../../components/title/title.component";
import { StyledLoginWrapper } from "./styled-login";

const LoginPage = () => {
  const { t } = useTranslation("auth");

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("login.meta.title")}</title>
        <meta
          name="description"
          content={t("login.meta.description")}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/login"}
          hrefLang={helmetHtmlByLang.ru}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua/login"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/en/login"}
          hrefLang={helmetHtmlByLang.en}
        />
      </Helmet>
      <StyledLoginWrapper>
        <Title
          as="h1"
          title={t("login.header.title")}
          subtitle={t("login.header.subTitle")}
        />
        <LoginContainer />
      </StyledLoginWrapper>
    </StyledContainer>
  );
};

export default LoginPage;
